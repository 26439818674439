import React from 'react';

const IconTrash = () => {
  return (
    <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.652 3.653V2.739C3.652 2.01257 3.94057 1.3159 4.45423 0.802234C4.9679 0.288572 5.66457 0 6.391 0H10.044C10.7704 0 11.4671 0.288572 11.9808 0.802234C12.4944 1.3159 12.783 2.01257 12.783 2.739V3.652H15.522C15.7641 3.652 15.9964 3.74819 16.1676 3.91941C16.3388 4.09063 16.435 4.32286 16.435 4.565C16.435 4.80714 16.3388 5.03937 16.1676 5.21059C15.9964 5.38181 15.7641 5.478 15.522 5.478V15.524C15.522 16.2504 15.2334 16.9471 14.7198 17.4608C14.2061 17.9744 13.5094 18.263 12.783 18.263H3.652C2.92557 18.263 2.2289 17.9744 1.71523 17.4608C1.20157 16.9471 0.913 16.2504 0.913 15.524V5.479C0.670857 5.479 0.438632 5.38281 0.267411 5.21159C0.0961908 5.04037 0 4.80814 0 4.566C0 4.32386 0.0961908 4.09163 0.267411 3.92041C0.438632 3.74919 0.670857 3.653 0.913 3.653H3.652ZM5.478 3.653H10.957V2.739C10.957 2.49686 10.8608 2.26463 10.6896 2.09341C10.5184 1.92219 10.2861 1.826 10.044 1.826H6.391C6.14886 1.826 5.91663 1.92219 5.74541 2.09341C5.57419 2.26463 5.478 2.49686 5.478 2.739V3.653ZM2.738 5.479H13.699V15.524C13.699 15.7661 13.6028 15.9984 13.4316 16.1696C13.2604 16.3408 13.0281 16.437 12.786 16.437H3.652C3.40986 16.437 3.17763 16.3408 3.00641 16.1696C2.83519 15.9984 2.739 15.7661 2.739 15.524L2.738 5.479ZM5.477 8.218C5.477 7.97586 5.57319 7.74363 5.74441 7.57241C5.91563 7.40119 6.14786 7.305 6.39 7.305C6.63214 7.305 6.86437 7.40119 7.03559 7.57241C7.20681 7.74363 7.303 7.97586 7.303 8.218V13.7C7.303 13.9421 7.20681 14.1744 7.03559 14.3456C6.86437 14.5168 6.63214 14.613 6.39 14.613C6.14786 14.613 5.91563 14.5168 5.74441 14.3456C5.57319 14.1744 5.477 13.9421 5.477 13.7V8.218ZM10.043 7.305C9.80086 7.305 9.56863 7.40119 9.39741 7.57241C9.22619 7.74363 9.13 7.97586 9.13 8.218V13.7C9.13 13.9421 9.22619 14.1744 9.39741 14.3456C9.56863 14.5168 9.80086 14.613 10.043 14.613C10.2851 14.613 10.5174 14.5168 10.6886 14.3456C10.8598 14.1744 10.956 13.9421 10.956 13.7V8.218C10.956 7.97603 10.8599 7.74396 10.6889 7.57276C10.5179 7.40157 10.286 7.30527 10.044 7.305H10.043Z"
        fill="white"
      />
    </svg>
  );
};

export default IconTrash;
