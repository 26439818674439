import { useApolloClient } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import * as constants from '../constants';
import { useCookie } from './useCookie';

const url = process.env.NEXT_PUBLIC_LOGOUT_ORIGIN;

export const useLogout = () => {
  const apolloClient = useApolloClient();
  const { logout: logoutAuth0 } = useAuth0();
  const [, , removeAuthToken] = useCookie(constants.cookie.token);
  // const [, , removeAnonymous] = useCookie(constants.cookie.anonymous);
  const [, , removeInviteCode] = useCookie(constants.cookie.inviteCode);

  const logout = async () => {
    await apolloClient.clearStore();
    removeAuthToken();
    removeInviteCode();
    logoutAuth0({ returnTo: url });
  };

  return logout;
};
