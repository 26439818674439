import { Router, useRouter } from 'next/router';

import React, { useState, useEffect, useRef, MouseEventHandler, Fragment, useCallback, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from '@auth0/auth0-react/src/auth-state';
import moment, { Duration } from 'moment';

import { useLogout } from '../../hooks/useLogout';
import { AlertColorEnum, alertStore } from '../../context/AlertContext';
import { useTextInput, useTextInputSettable } from '../../common/custom-hook';

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Popover,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledPopover,
  Spinner,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faDownload,
  faCheckCircle,
  faTimes,
  faUsers,
  faTimesCircle,
  faBell,
  faClone,
} from '@fortawesome/free-solid-svg-icons';
import TimeCodeSVG from '../svg/TimeCode';
import ModalShare from '../Modal/ModalSharing';
import ModalConfirm from '../Modal/ModalConfirm';
import { KeyBindingUtil } from 'draft-js';
import ModalProfile from '../Modal/ModalProfile';
import CustomToolTip from './CustomToolTip';
import Notification from '../Notification/Notification';
// import ModalPrice from '../Modal/ModalPrice';
// import ModalShareSocialMedia from '../Modal/ModalShareSocialMedia';
const { hasCommandModifier } = KeyBindingUtil;

import type { InputMaybe } from '@root/graphql/generated/operations';
import {
  ApplicationType,
  TGetAssemblyProjectsQueryResult,
  GetAssemblyProjectsDocument,
  useGetPaymentLinkLazyQuery,
  useGetUserProfileLazyQuery,
  useCloneAssemblyProjectMutation,
  useGetAssemblyProjectLazyQuery,
  useAmendAssemblyProjectMutation,
  useAmendAssemblyMediaMutation,
} from '@root/graphql/generated/operations';

declare global {
  interface Window {
    $crisp: any;
  }
}
type AuthButtonProps = {
  user: User;
  isLoading: boolean;
  isAuthenticated: boolean;
  handleLogoutClicked: MouseEventHandler;
  handleLoginClicked: MouseEventHandler;
  handleProfileClicked: MouseEventHandler;
};

const AuthButton = ({
  user,
  isLoading,
  isAuthenticated,
  handleLogoutClicked,
  handleLoginClicked,
  handleProfileClicked,
}: AuthButtonProps) => {
  const [getUserData, { data: userData }] = useGetUserProfileLazyQuery();

  useEffect(() => {
    if (isAuthenticated) getUserData();
  }, [isAuthenticated]);

  // useEffect(() => {
  //   const userInfo = userData?.getUserProfile;

  //   if (!userInfo || !window.$crisp) return;

  //   window.$crisp.push(['set', 'user:email', [userInfo.email]]);
  //   window.$crisp.push(['set', 'user:nickname', [userInfo.full_name]]);
  //   window.$crisp.push(['set', 'user:avatar', [userInfo.avatar]]);
  // }, [userData]);

  if (isAuthenticated) {
    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          <img src={userData?.getUserProfile?.avatar || user.picture} alt={user.name} className="user-picture" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={handleProfileClicked}>Profile</DropdownItem>
          <DropdownItem onClick={handleLogoutClicked}>Sign out</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
  if (isLoading === false) {
    return (
      <NavItem>
        <Button className="btn btn-simon" onClick={handleLoginClicked}>
          Sign up / Login
        </Button>
      </NavItem>
    );
  }
  return (
    <NavItem>
      <FontAwesomeIcon icon={faSpinner} className="fa-spin" width={16} height={16} />
    </NavItem>
  );
};

type NavigationProps = {
  projectid?: string;
  hasUnsavedChanges?: boolean;
  isSaving?: boolean;
  saveEnabled?: boolean;
  isReadyForExport?: boolean;
  canOpenShareModal?: boolean;
  name: string;
  projectInfo?: {
    height?: number | null;
    width?: number | null;
    fps?: string | null;
    timecode?: string | null;
  };
  medias?: ImportedMedia[];
  visitors?: Visitor[];
  notification?: string;
  requestExport?: (fileType: string, extension: string) => void;
  handleSaveClicked?: MouseEventHandler | null;
  saveName?: ((name: string) => void) | null;
};

type exportItem = {
  title: string;
  name: string;
  fileType: string;
  extension: string;
  img: JSX.Element;
  options: [JSX.Element?];
  disable?: boolean;
  beta?: boolean;
  disabledOnProjectStatus?: boolean;
};

type ModalExportProps = {
  isOpen: boolean;
  selectedExport: exportItem | null;
  toggle?: () => void;
  handleDownload?: () => void;
  selectedItem: (item: exportItem) => void;
};

const exportSupported: Array<exportItem> = [
  {
    title: 'Microsoft Word',
    name: 'microsoftWord',
    fileType: 'word',
    extension: 'docx',
    img: <img src="/export-images/microsoft-word.png" />,
    disable: false,
    options: [],
  },
  {
    title: 'Adobe \n Premiere Pro',
    name: 'adobePremiere',
    fileType: 'premiere',
    extension: 'xml',
    img: <img src="/export-images/adobe-premiere.png" />,
    disable: false,
    options: [],
  },
  {
    title: 'Final Cut Pro',
    name: 'finalCutProX',
    fileType: 'fcpxml',
    extension: 'fcpxml',
    img: <img src="/export-images/final-cut-x.png" />,
    options: [],
  },
  {
    title: 'SRT Subtitles',
    name: 'srt',
    fileType: 'srt',
    extension: 'srt',
    img: <img src="/export-images/srt.png" />,
    disable: true,
    options: [],
  },
  {
    title: 'Avid Media\nComposer',
    name: 'avidMediaComposer',
    fileType: 'avid',
    extension: 'edl',
    img: <img src="/export-images/avid-media-composer.png" />,
    options: [],
  },
  {
    title: 'DaVinci Resolve',
    name: 'davinciResolve',
    // fileType: 'resolve',
    fileType: 'premiere', // temporarily fix
    extension: 'xml',
    img: <img src="/export-images/davinci-resolve.png" />,
    disable: false,
    options: [],
  },
  // {
  //   title: 'Avid Pro Tools',
  //   name: 'avidProTools',
  //   fileType: 'protools',
  //   extension: 'srt',
  //   img: <img src="/export-images/avid-pro-tools.png" />,
  //   disable: true,
  //   options: [],
  // },
  {
    title: 'Adobe Audition',
    name: 'adobeAudition',
    fileType: 'audition',
    extension: 'xml',
    img: <img src="/export-images/adobe-audition.png" />,
    disable: false,
    options: [],
  },
  {
    title: 'Video File',
    name: 'mp4',
    fileType: 'mp4',
    extension: 'mp4',
    img: <img src="/export-images/mp4.png" />,
    disable: false,
    options: [],
    beta: true,
  },
];

const ModalExport = (props: ModalExportProps) => {
  const { isOpen, toggle, handleDownload, selectedExport, selectedItem } = props;

  function isSelectedExport(item: exportItem) {
    if (!selectedExport) return false;
    return item.name === (selectedExport?.name || '');
  }

  return (
    <Modal id="modal-export" isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <div style={{ textAlign: 'center', position: 'relative' }}>
          <span>Export</span>
          <span
            onClick={toggle}
            style={{
              position: 'absolute',
              right: 0,
              color: '#828282',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faTimes} width={16} height={16} />
          </span>
        </div>
        <div className="export__container">
          {exportSupported.map((item, index) => (
            <div className={`export__item ${isSelectedExport(item) ? 'active' : ''}`} key={'exportSupported-' + index}>
              <span className="export__item-icon">
                <FontAwesomeIcon icon={faCheckCircle} width={16} height={16} />
              </span>
              <div className="export-type">
                <button
                  className="export__item-image"
                  // disabled={
                  //   activeProject?.status ===
                  //   item.disabledOnProjectStatus
                  // }
                  disabled={item.disable}
                  onClick={() => selectedItem(item)}
                >
                  {item.img}
                </button>
              </div>
              <div className="export__item-title">
                {item.title}
                {item.disable && (
                  <small>
                    <br />
                    Coming soon
                  </small>
                )}
                {item.beta && (
                  <small>
                    <br />
                    Beta
                  </small>
                )}
              </div>
            </div>
          ))}
        </div>
        <div style={{ textAlign: 'center' }}>
          <button className="ss-btn" onClick={handleDownload}>
            <span>Download</span>
            <span style={{ padding: '0 8px' }}>
              <FontAwesomeIcon icon={faDownload} width={16} height={16} />
            </span>
          </button>
          <span style={{ position: 'absolute', bottom: 30, right: 10, fontSize: 12 }}>
            <a
              href="https://storylines.crisp.help/en/category/export-to-your-video-editing-application-19vchuz"
              target="_blank"
              rel="noreferrer"
            >
              Learn about exports
            </a>
          </span>
        </div>
      </ModalBody>
    </Modal>
  );
};

const Navigation = ({
  hasUnsavedChanges,
  isSaving,
  saveEnabled,
  isReadyForExport,
  canOpenShareModal,
  name,
  projectid,
  // projectInfo,
  medias,
  visitors,
  notification,
  requestExport,
  handleSaveClicked,
  saveName,
}: NavigationProps) => {
  const projectId = projectid || '';

  const { showAlert } = useContext(alertStore);

  const [getUserData, { data: userData }] = useGetUserProfileLazyQuery();
  const [getPaymentLinkQuery] = useGetPaymentLinkLazyQuery({
    onCompleted: ({ getPaymentLink }) => {
      window.open(getPaymentLink, '_self');
    },
  });

  const [cloneAssemblyProject] = useCloneAssemblyProjectMutation({
    onCompleted: (data) => {
      if (!data.cloneAssemblyProject) {
        showAlert({
          type: AlertColorEnum.danger,
          message:
            'Uh oh. I wasn’t able to clone the project for you. Reach out to support and we’ll get that fixed for you right away!',
        });
        return;
      }

      router.push({
        pathname: '/[projectid]',
        query: { projectid: data.cloneAssemblyProject.id },
      });
    },
    onError: () => {
      showAlert({
        type: AlertColorEnum.danger,
        message:
          'Uh oh. I wasn’t able to clone the project for you. Reach out to support and we’ll get that fixed for you right away!',
      });
    },
    update(cache, { data }) {
      const existedCache = cache.readQuery<TGetAssemblyProjectsQueryResult>({
        query: GetAssemblyProjectsDocument,
      });

      if (!existedCache?.getAssemblyProjects?.length) return;

      cache.writeQuery({
        query: GetAssemblyProjectsDocument,
        data: {
          getAssemblyProjects: [...existedCache?.getAssemblyProjects, data?.cloneAssemblyProject],
        },
      });
    },
  });

  const [
    getAssemblyProject,
    {
      data: assemblyProjectData,
      loading: isProjectLoading,
      refetch: refetchAssemblyProject,
      loading: loadingFetchAssemblyProject,
    },
  ] = useGetAssemblyProjectLazyQuery({
    variables: { projectId: projectId },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (!projectId) return;

    getAssemblyProject({ variables: { projectId } });
  }, [projectId]);

  const projectInfo = {
    height: assemblyProjectData?.getAssemblyProject?.height,
    width: assemblyProjectData?.getAssemblyProject?.width,
    timecode: assemblyProjectData?.getAssemblyProject?.timecode,
    fps: assemblyProjectData?.getAssemblyProject?.fps,
  };

  const [amendedAssemblyProject] = useAmendAssemblyProjectMutation({
    onCompleted() {
      refetchAssemblyProject();
    },
  });
  const [amendedAssemblyMedia] = useAmendAssemblyMediaMutation();

  const userHasWritePermission = assemblyProjectData?.getAssemblyProject?.my_privilege === 'EDIT';

  const [isOpenToolTip, setIsOpenToolTip] = useState(false);
  const frameRateOptions = useRef([
    {
      fps: 23.976,
      text: '23.976',
      dropFrame: false,
    },
    {
      fps: 24,
      text: '24',
      dropFrame: false,
    },
    {
      fps: 25,
      text: '25',
      dropFrame: false,
    },
    {
      fps: 29.97,
      text: '29.97DF',
      dropFrame: true,
    },
    {
      fps: 29.97,
      text: '29.97NDF',
      dropFrame: false,
    },
    {
      fps: 30,
      text: '30',
      dropFrame: false,
    },
    {
      fps: 50,
      text: '50',
      dropFrame: false,
    },
    {
      fps: 59.94,
      text: '59.94DF',
      dropFrame: true,
    },
    {
      fps: 59.94,
      text: '59.94NDF',
      dropFrame: false,
    },
    {
      fps: 60,
      text: '60',
      dropFrame: false,
    },
    {
      fps: 0,
      text: '',
      dropFrame: false,
    },
  ]);
  const router = useRouter();
  const { loginWithRedirect, user, isAuthenticated, isLoading } = useAuth0();
  const logout = useLogout();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenExportModal, setIsOpenExportModal] = useState(false);
  const [isOpenShareModal, setIsOpenShareModal] = useState(false);
  // const [isOpenPriceModal, setIsOpenPriceModal] = useState(false);
  const [isOpenProfileModal, setIsOpenProfileModal] = useState(false);
  const [selectedExportItem, setSelectedExportItem] = useState<exportItem | null>(null);
  const [localName, setLocalName] = useState(name);
  const [currentProjectFrameRate, setCurrentProjectFrameRate] = useState({
    fps: 0,
    text: ' ',
    dropFrame: false,
  });

  const [currentMediaFrameRate, setCurrentMediaFrameRate] = useState({
    fps: 0,
    text: ' ',
    dropFrame: false,
  });

  const [isOpenTimePopover, setIsOpenTimePopover] = useState(false);
  const [isOpenModalConfirmCloningProject, setIsOpenModalConfirmCloningProject] = useState(false);
  const [selectedMediaIdx, setSelectedMediaIdx] = useState(() => (medias?.length ? 0 : null));
  const selectedMedia = medias && selectedMediaIdx !== null ? medias[selectedMediaIdx] : null;

  const inputHeight = useTextInputSettable(projectInfo?.height || '', isNumberOrEmpty);
  const inputWidth = useTextInputSettable(projectInfo?.width || '', isNumberOrEmpty);
  const inputTimecode = useTextInputSettable(projectInfo?.timecode || '00:00:00:00', isTimeCode);

  const inputMediaHeight = useTextInputSettable(selectedMedia?.height || '', isNumberOrEmpty);
  const inputMediaWidth = useTextInputSettable(selectedMedia?.width || '', isNumberOrEmpty);
  const inputMediaTimecode = useTextInputSettable(selectedMedia?.startTimeCode || '00:00:00:00', isTimeCode);

  const inputHeightRef = useRef<HTMLInputElement>(null);
  const inputWidthRef = useRef<HTMLInputElement>(null);
  const currentRefToolTip = useRef<any>(null);

  function isNumberOrEmpty(event: any) {
    const value = event.target.value;
    return /\d|^$/gi.test(value);
  }

  function isTimeCode(event: any) {
    const value = event.target.value;
    if (currentProjectFrameRate.dropFrame) {
      return /^\d{0,2}:\d{0,2}:[0-5]?[0-9]?;[0-5]?[0-9]?$/.test(value);
    }
    return /^\d{0,2}:\d{0,2}:[0-5]?[0-9]?:[0-5]?[0-9]?$/.test(value);
  }

  useEffect(() => {
    setSelectedMediaIdx((prev) => (prev === null && medias?.length ? 0 : prev));
  }, [medias]);

  useEffect(() => {
    if (!isAuthenticated) return;

    getUserData();
  }, [isAuthenticated]);

  useEffect(() => {
    setLocalName(name);
  }, [name]);

  useEffect(() => {
    if (projectInfo?.fps && projectInfo?.timecode) {
      const isDropFrame = /^\d{0,2}:\d{0,2}:[0-5]?[0-9]?;[0-5]?[0-9]?$/.test(projectInfo.timecode);
      const item = frameRateOptions.current.find(
        (item) => item.dropFrame === isDropFrame && item.fps == Number(projectInfo.fps),
      );
      if (item) setCurrentProjectFrameRate(item);
    }
  }, [projectInfo?.fps, projectInfo?.timecode]);

  useEffect(() => {
    if (selectedMedia?.fps && selectedMedia?.startTimeCode) {
      const isDropFrame = /^\d{0,2}:\d{0,2}:[0-5]?[0-9]?;[0-5]?[0-9]?$/.test(selectedMedia.startTimeCode);
      const item = frameRateOptions.current.find(
        (item) => item.dropFrame === isDropFrame && item.fps == Number(selectedMedia.fps),
      );
      if (item) setCurrentMediaFrameRate(item);
    }
  }, [selectedMedia?.fps, selectedMedia?.startTimeCode]);

  useEffect(() => {
    const [hh, mm, ss, ff] = inputTimecode.value.split(/:|;/);

    if (currentProjectFrameRate.dropFrame) {
      inputTimecode.setvalue(`${hh}:${mm}:${ss};${ff}`);
    } else {
      inputTimecode.setvalue(`${hh}:${mm}:${ss}:${ff}`);
    }
  }, [currentProjectFrameRate, inputTimecode]);

  useEffect(() => {
    // @ts-ignore
    function handleKeydown(e) {
      if (e.keyCode === 69 /* `E` key */ && hasCommandModifier(e)) {
        e.preventDefault();
        setIsOpenExportModal((prevState) => !prevState);
      }
    }

    // @ts-ignore
    document.body.addEventListener('keydown', handleKeydown, false);

    return () => {
      // @ts-ignore
      document.body.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  const editableName = useRef<HTMLInputElement>(null);

  const toggle = () => setIsOpen(!isOpen);

  const handleExportClicked = () => {
    refetchAssemblyProject();

    if (!requestExport) {
      return;
    }
    setIsOpenExportModal(true);
  };

  function handleAmendAssemblyProject(mediaInfo: {
    height: InputMaybe<number>;
    width: InputMaybe<number>;
    fps: InputMaybe<string>;
    timecode: InputMaybe<string>;
  }) {
    amendedAssemblyProject({ variables: { projectId: projectId, ...mediaInfo } });
  }

  const handleConfirmAmendProjectInfo = useCallback(() => {
    const height = +inputHeight.value;
    const width = +inputWidth.value;
    let timecode = inputTimecode.value;
    const fps = currentProjectFrameRate.fps + '';
    let [hh, mm, ss, ff] = timecode.trim().split(/:|;/);

    function normalizeTimeNumber(timeStr: string) {
      if (!timeStr) return '00';

      const num = Number(timeStr);
      if (!num) return '00';
      return num < 10 ? '0' + num : num + '';
    }

    hh = normalizeTimeNumber(hh);
    mm = normalizeTimeNumber(mm);
    ss = normalizeTimeNumber(ss);
    ff = normalizeTimeNumber(ff);

    timecode = `${hh}:${mm}:${ss}${currentProjectFrameRate.dropFrame ? ';' : ':'}${ff}`;

    handleAmendAssemblyProject({
      height,
      width,
      timecode,
      fps,
    });
    setIsOpenTimePopover((prev) => !prev);
  }, [inputHeight, inputWidth, inputTimecode, currentProjectFrameRate]);

  function handleCancelAmendProjectInfo() {
    if (isProjectLoading) return;

    inputHeight.setvalue(projectInfo?.height || '');
    inputWidth.setvalue(projectInfo?.width || '');
    inputTimecode.setvalue(projectInfo?.timecode || '00:00:00:00');

    if (projectInfo?.fps && projectInfo?.timecode) {
      const isDropFrame = /^\d{0,2}:\d{0,2}:[0-5]?[0-9]?;[0-5]?[0-9]?$/.test(projectInfo.timecode);
      const item = frameRateOptions.current.find(
        (item) => item.dropFrame === isDropFrame && item.fps == Number(projectInfo.fps),
      );
      if (item) setCurrentProjectFrameRate(item);
    } else {
      setCurrentProjectFrameRate({
        fps: 0,
        text: ' ',
        dropFrame: false,
      });
    }

    setIsOpenTimePopover(!isOpenTimePopover);
  }

  const handleConfirmAmendMediaInfo = useCallback(() => {
    if (!selectedMedia) return;

    const mediaId = selectedMedia.id as string;
    const height = +inputMediaHeight.value;
    const width = +inputMediaWidth.value;
    let startTimeCode = inputMediaTimecode.value;
    const fps = currentMediaFrameRate.fps;
    let [hh, mm, ss, ff] = startTimeCode.trim().split(/:|;/);

    function normalizeTimeNumber(timeStr: string) {
      if (!timeStr) return '00';

      const num = Number(timeStr);
      if (!num) return '00';
      return num < 10 ? '0' + num : num + '';
    }

    hh = normalizeTimeNumber(hh);
    mm = normalizeTimeNumber(mm);
    ss = normalizeTimeNumber(ss);
    ff = normalizeTimeNumber(ff);

    startTimeCode = `${hh}:${mm}:${ss}${currentMediaFrameRate.dropFrame ? ';' : ':'}${ff}`;

    amendedAssemblyMedia({
      variables: {
        input: {
          applicationType: ApplicationType.WebApp,
          mediaId,
          height,
          width,
          startTimeCode,
          fps,
        },
      },
    });

    setIsOpenTimePopover((prev) => !prev);
  }, [
    inputMediaHeight,
    inputMediaWidth,
    inputMediaTimecode,
    selectedMedia,
    currentMediaFrameRate,
    amendedAssemblyMedia,
  ]);

  function handleCancelAmendMediaInfo() {
    inputMediaHeight.setvalue(selectedMedia?.height || '');
    inputMediaWidth.setvalue(selectedMedia?.width || '');
    inputMediaTimecode.setvalue(selectedMedia?.startTimeCode || '00:00:00:00');

    if (selectedMedia?.fps && selectedMedia?.startTimeCode) {
      const isDropFrame = /^\d{0,2}:\d{0,2}:[0-5]?[0-9]?;[0-5]?[0-9]?$/.test(selectedMedia.startTimeCode);
      const item = frameRateOptions.current.find(
        (item) => item.dropFrame === isDropFrame && item.fps == Number(selectedMedia.fps),
      );
      if (item) setCurrentMediaFrameRate(item);
    }

    setIsOpenTimePopover(!isOpenTimePopover);
  }

  useEffect(() => {
    let height = +inputHeight.value;
    let width = +inputWidth.value;
    if (height % 2 !== 0 && height !== 0) {
      currentRefToolTip.current = inputHeightRef.current;
      setIsOpenToolTip(true);
    } else if (width % 2 !== 0 && width !== 0) {
      currentRefToolTip.current = inputWidthRef.current;
      setIsOpenToolTip(true);
    } else {
      height = 0;
      width = 0;
      setIsOpenToolTip(false);
      currentRefToolTip.current = null;
    }
  }, [inputHeight, inputWidth]);

  useEffect(() => {
    const TIME_OUT = 5000;
    let currentTime = 0;
    let newDIV: HTMLDivElement | null = null;

    const job = setInterval(() => {
      if (!isOpenTimePopover || currentTime >= TIME_OUT) {
        clearInterval(job);
        return;
      }

      const popoverEl = document.querySelector('div.fade.show .timecode-popover')?.parentNode;
      if (popoverEl) {
        clearInterval(job);
        newDIV = document.createElement('div');
        newDIV.classList.add('timecode-popover__overlay');
        /** [STOR - 178]
         * Disbale this code for fixing the update meta project
          newDIV.onclick = handleConfirmAmendProjectInfo;
         */
        popoverEl.prepend(newDIV);
      }

      currentTime += 300;
    }, 300);

    return () => {
      if (newDIV !== null) newDIV.remove();
      clearInterval(job);
    };
  }, [isOpenTimePopover, handleConfirmAmendProjectInfo]);

  function cloneProject() {
    if (projectid) cloneAssemblyProject({ variables: { projectId: projectid } });
    setIsOpenModalConfirmCloningProject((prev) => !prev);
  }

  function getPaymentLink() {
    getPaymentLinkQuery();
  }

  function formatCredit(credit: number) {
    const duration = moment.duration(credit, 'minutes');
    const hours = duration.days() * 24 + duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const hourText = hours > 1 ? ' hours' : ' hour';
    const minuteText = minutes > 1 ? ' minutes' : ' minute';
    const secondText = seconds > 1 ? ' seconds' : ' second';

    let creditInText = '';

    creditInText += (hours && hours + hourText + '') || '';
    creditInText += (hours && minutes && ' and ') || '';
    creditInText += (minutes && minutes + minuteText + '') || '';
    creditInText += (minutes && seconds && ' and ') || '';
    creditInText += (seconds && seconds + secondText) || '';

    return creditInText;
  }

  function handleDownload() {
    if (projectInfo?.fps === '0' || projectInfo?.height === 0 || projectInfo?.width === 0 || !projectInfo?.timecode) {
      showAlert({
        type: AlertColorEnum.info,
        message: (
          <span>
            <span>{'Please '}</span>
            <span>
              <a
                href="https://storylines.crisp.help/en/article/set-media-and-project-timecode-framerate-and-resolution-aqiiku"
                target="_blank"
                rel="noreferrer"
              >
                {'set '}
              </a>
            </span>
            <span>{"the timeline's framerate, width, height, timecode and then export."}</span>
          </span>
        ),
      });
      return;
    }

    if (requestExport && selectedExportItem) {
      requestExport(selectedExportItem.fileType, selectedExportItem.extension);
    }
  }

  const credit: number = userData?.getUserProfile?.credit || 0;

  return (
    <div id="navigation">
      <Navbar fixed="top" dark color="dark" expand="md">
        <NavbarBrand href="/">
          <img src="/logo.png" className="logo" alt="Storylines" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavbarText>
                <input
                  ref={editableName}
                  value={localName}
                  onChange={(e) => setLocalName(e.target.value)}
                  onBlur={(e) => {
                    if (saveName) {
                      const newName = e.target.value.trim() || 'Untitled Project';
                      setLocalName(newName);
                      editableName.current?.setSelectionRange(0, 0);
                      saveName(newName);
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      editableName.current?.blur();
                    }
                  }}
                  className="invisible-input"
                  style={{ width: '400px' }}
                  disabled={!saveName}
                />
              </NavbarText>
            </NavItem>
            <NavItem style={{ width: '100px' }}>
              {isSaving && <FontAwesomeIcon icon={faSpinner} className="fa-spin" width={16} height={16} />}
              {handleSaveClicked && hasUnsavedChanges && !isSaving && saveEnabled && (
                <Button onClick={handleSaveClicked} color="link" size="sm" className="save-now">
                  Save now
                </Button>
              )}

              {notification && <span style={{ fontSize: 14 }}>{notification}</span>}
            </NavItem>
          </Nav>
          <Nav className="ml-auto" navbar>
            {/* {isAuthenticated && (
              <NavItem>
                <button className="btn-upgrade" onClick={() => setIsOpenPriceModal(prevState => !prevState)}>
                Upgrade
              </button>
                <button className="btn-upgrade" onClick={getPaymentLink}>
                  Add credit
                </button>
                <div style={{ fontSize: 11 }}>
                  <span>{formatCredit(credit)}</span>
                  <span> | </span>
                  <span style={{ cursor: 'pointer' }} onClick={getPaymentLink}>
                    Add more
                  </span>
                </div>
              </NavItem>
            )} */}

            <NavItem>
              <div className="visitors">
                {visitors &&
                  visitors.length > 0 &&
                  visitors.slice(0, 4).map((visitor, index) => (
                    <div
                      key={'visitor-' + index}
                      className="visitors__avatar"
                      style={{ zIndex: index }}
                      title={visitor.full_name}
                    >
                      <img src={visitor.avatar} alt={visitor.full_name} />
                    </div>
                  ))}
                {visitors && visitors.length > 0 && visitors.length >= 5 && (
                  <React.Fragment>
                    <div
                      id="PopoverClick"
                      className="visitors__avatar-holder"
                      title={`${visitors.length - 4} additional other viewer${visitors.length === 5 ? '' : 's'}`}
                    >
                      <div>{visitors.length - 4 < 9 ? visitors.length - 4 : '9+'}</div>
                    </div>
                    <UncontrolledPopover trigger="click" placement="bottom" target="PopoverClick">
                      <PopoverBody>
                        <div className="visitors__popover">
                          <span>{visitors.length} total viewers</span>
                          {visitors.map((visitor, index) => (
                            <React.Fragment key={'visitor-' + index}>
                              <div className="visitor-popover">
                                <div className="visitor-popover__avatar">
                                  <img src={visitor.avatar} alt={visitor.full_name} title={visitor.full_name} />
                                </div>
                                <div
                                  className="visitor-popover__fullname"
                                  style={{ marginLeft: 8 }}
                                  title={visitor.full_name}
                                >
                                  {visitor.full_name}
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </React.Fragment>
                )}
              </div>
            </NavItem>
            {/* {isAuthenticated && projectid && (
              <NavItem>
                <ModalShareSocialMedia projectid={projectid} />
              </NavItem>
            )} */}
            {isAuthenticated && (
              <NavItem>
                <Notification />
              </NavItem>
            )}

            {canOpenShareModal && (
              <NavItem id="nav-share-icon">
                <span style={{ cursor: 'pointer' }} onClick={() => setIsOpenShareModal((prevState) => !prevState)}>
                  <FontAwesomeIcon icon={faUsers} width={16} height={16} />
                </span>
              </NavItem>
            )}
            {userHasWritePermission && (
              <NavItem>
                <span id="timecode-popover" style={{ cursor: 'pointer' }}>
                  <span
                    onClick={() => {
                      if (!isOpenTimePopover) {
                        refetchAssemblyProject();
                      }
                      setIsOpenTimePopover((prev) => !prev);
                    }}
                  >
                    <TimeCodeSVG />
                  </span>
                  {console.log('loadingFetchAssemblyProject: ', loadingFetchAssemblyProject)}
                  <Popover
                    popperClassName="timecode-popover"
                    isOpen={isOpenTimePopover}
                    placement="bottom"
                    target="timecode-popover"
                  >
                    <CustomToolTip isOpen={isOpenToolTip} element={currentRefToolTip} placement="bottom" />
                    <PopoverBody
                      className={
                        'timecode-popover__body' + ` ${loadingFetchAssemblyProject ? 'timecode-popover__loading' : ''}`
                      }
                    >
                      {loadingFetchAssemblyProject && (
                        <Spinner className="timecode-popover__spinner-loading" color="info">
                          Loading...
                        </Spinner>
                      )}
                      <div style={{ flex: 1, margin: '0 8px' }}>
                        <div>
                          <div className="timecode-popover__label">Media</div>
                          <UncontrolledDropdown>
                            <DropdownToggle caret>
                              <span
                                title={selectedMedia?.name}
                                style={{
                                  display: 'inline-block',
                                  textAlign: 'left',
                                  whiteSpace: 'nowrap',
                                  width: '100px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {selectedMedia?.name}
                              </span>
                            </DropdownToggle>
                            <DropdownMenu>
                              {medias?.map((media, index) => (
                                <DropdownItem onClick={() => setSelectedMediaIdx(index)} key={index}>
                                  {media?.name}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div>
                          <div className="timecode-popover__label">Frame Rate</div>
                          <UncontrolledDropdown>
                            <DropdownToggle caret>
                              <span style={{ width: 70, display: 'inline-block', textAlign: 'left' }}>
                                {currentMediaFrameRate.text}
                              </span>
                            </DropdownToggle>
                            <DropdownMenu>
                              {frameRateOptions.current.map((option, index) => (
                                <DropdownItem onClick={() => setCurrentMediaFrameRate(option)} key={index}>
                                  {option.text}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div>
                          <div className="timecode-popover__label">Resolution</div>
                          <div className="timecode-popover__resolution">
                            <div style={{ marginRight: 4 }}>
                              <label>Width</label>
                              <input value={inputMediaWidth.value} onChange={inputMediaWidth.onChange} />
                            </div>
                            <div>
                              <label>Height</label>
                              <input value={inputMediaHeight.value} onChange={inputMediaHeight.onChange} />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="timecode-popover__label">Start Timecode</div>
                          <input value={inputMediaTimecode.value} onChange={inputMediaTimecode.onChange} />
                        </div>
                        <div style={{ textAlign: 'right', marginTop: 8 }}>
                          <span className={`timecode-popover__btn-confirm`} onClick={handleConfirmAmendMediaInfo}>
                            <FontAwesomeIcon icon={faCheckCircle} width={16} height={16} />
                          </span>
                          <span className="timecode-popover__btn-cancel" onClick={handleCancelAmendMediaInfo}>
                            <FontAwesomeIcon icon={faTimesCircle} width={16} height={16} />
                          </span>
                        </div>
                      </div>

                      <div style={{ flex: 1, margin: '0 8px' }}>
                        <div style={{ color: '#fff' }}>Project</div>
                        <div>
                          <div className="timecode-popover__label">Frame Rate</div>
                          <UncontrolledDropdown>
                            <DropdownToggle caret>
                              <span style={{ width: 70, display: 'inline-block', textAlign: 'left' }}>
                                {currentProjectFrameRate.text}
                              </span>
                            </DropdownToggle>
                            <DropdownMenu>
                              {frameRateOptions.current.map((option, index) => (
                                <DropdownItem onClick={() => setCurrentProjectFrameRate(option)} key={index}>
                                  {option.text}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                        <div style={{ padding: '8px 0' }}>
                          <div className="timecode-popover__label">Resolution</div>
                          <div className="timecode-popover__resolution">
                            <div style={{ marginRight: 4 }}>
                              <label>Width</label>
                              <input
                                value={inputWidth.value}
                                onChange={inputWidth.onChange}
                                ref={inputWidthRef}
                                className={isOpenToolTip ? 'input-error' : ''}
                              />
                            </div>
                            <div>
                              <label>Height</label>
                              <input
                                value={inputHeight.value}
                                onChange={inputHeight.onChange}
                                ref={inputHeightRef}
                                className={isOpenToolTip ? 'input-error' : ''}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div>
                        <div className="timecode-popover__label">Start Timecode</div>
                        <input value={inputTimecode.value} onChange={inputTimecode.onChange} />
                      </div> */}
                        <div style={{ textAlign: 'right', marginTop: 8 }}>
                          <span
                            className={`timecode-popover__btn-confirm ` + `${isOpenToolTip ? 'btn-disable' : ''}`}
                            onClick={handleConfirmAmendProjectInfo}
                          >
                            <FontAwesomeIcon icon={faCheckCircle} width={16} height={16} />
                          </span>
                          <span className="timecode-popover__btn-cancel" onClick={handleCancelAmendProjectInfo}>
                            <FontAwesomeIcon icon={faTimesCircle} width={16} height={16} />
                          </span>
                        </div>
                      </div>
                    </PopoverBody>
                  </Popover>
                </span>
              </NavItem>
            )}
            {canOpenShareModal && (
              <NavItem>
                <span
                  title="Clone Project"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setIsOpenModalConfirmCloningProject((prevState) => !prevState)}
                >
                  <FontAwesomeIcon icon={faClone} width={16} height={16} />
                </span>
              </NavItem>
            )}

            <NavItem>
              {requestExport && (
                <Fragment>
                  <Button
                    id="exportButton"
                    className="btn btn-simon"
                    onClick={handleExportClicked}
                    disabled={!isReadyForExport}
                  >
                    Export Project
                  </Button>
                </Fragment>
              )}
            </NavItem>
            <AuthButton
              user={user}
              isLoading={isLoading}
              isAuthenticated={isAuthenticated}
              handleLoginClicked={() => loginWithRedirect()}
              handleLogoutClicked={() => {
                logout();
              }}
              handleProfileClicked={() => setIsOpenProfileModal((prevState) => !prevState)}
            />
          </Nav>
        </Collapse>
      </Navbar>
      <ModalExport
        isOpen={isOpenExportModal}
        toggle={() => setIsOpenExportModal((prevState) => !prevState)}
        selectedItem={setSelectedExportItem}
        selectedExport={selectedExportItem}
        handleDownload={handleDownload}
      />
      {projectid && <ModalShare isOpen={isOpenShareModal} toggle={setIsOpenShareModal} projectid={projectid} />}

      <ModalConfirm
        isOpen={isOpenModalConfirmCloningProject}
        toggle={() => setIsOpenModalConfirmCloningProject((prev) => !prev)}
        cancel={() => setIsOpenModalConfirmCloningProject(false)}
        confirm={() => cloneProject()}
        title="Clone the current project?"
        confirmText="Clone"
        cancelText="Cancel"
        swapActive
      />

      <ModalProfile isOpen={isOpenProfileModal} toggle={setIsOpenProfileModal} />

      {/* <ModalPrice isOpen={isOpenPriceModal} toggle={setIsOpenPriceModal} /> */}
    </div>
  );
};

export default Navigation;
