import React from 'react';

function IconFile() {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.74988 6.05502e-07H8.22328C8.47776 -0.000201042 8.72977 0.0499645 8.96477 0.147607C9.19978 0.24525 9.41313 0.388439 9.59254 0.568919L13.3599 4.36621C13.4495 4.45649 13.4997 4.57848 13.4998 4.70563V6.26775C13.4998 6.39562 13.449 6.51825 13.3585 6.60867C13.2681 6.69908 13.1455 6.74988 13.0176 6.74988H8.67842C8.16694 6.74988 7.67641 6.5467 7.31474 6.18502C6.95306 5.82335 6.74988 5.33282 6.74988 4.82134V2.24964H2.24964V15.7494H11.2501V7.88097H13.4998V15.7494C13.4999 16.0449 13.4418 16.3375 13.3288 16.6106C13.2158 16.8836 13.0501 17.1318 12.8412 17.3408C12.6323 17.5498 12.3842 17.7155 12.1112 17.8287C11.8382 17.9418 11.5456 18 11.2501 18H2.24964C1.95413 18 1.66152 17.9418 1.38852 17.8287C1.11551 17.7155 0.867474 17.5498 0.658563 17.3408C0.449652 17.1318 0.283965 16.8836 0.170966 16.6106C0.0579682 16.3375 -0.000126458 16.0449 2.06679e-07 15.7494V2.24964C2.06679e-07 1.653 0.237014 1.08079 0.658903 0.658906C1.08079 0.237016 1.653 6.05502e-07 2.24964 6.05502e-07H6.74988Z"
        fill="white"
      />
    </svg>
  );
}

export default IconFile;
