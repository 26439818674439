import React from 'react';

function IconGD() {
  return (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="19.4018" cy="19.4018" r="19.4018" fill="white" />
      <path
        d="M9.78833 26.3559L10.8002 28.1036C11.0104 28.4716 11.3127 28.7607 11.6675 28.971L15.2813 22.7158H8.05371C8.05371 23.1232 8.15884 23.5306 8.3691 23.8985L9.78833 26.3559Z"
        fill="#0066DA"
      />
      <path
        d="M19.5258 15.3567L15.912 9.10156C15.5572 9.31182 15.255 9.60092 15.0447 9.96887L8.3691 21.533C8.16278 21.893 8.05397 22.3007 8.05371 22.7157H15.2813L19.5258 15.3567Z"
        fill="#00AC47"
      />
      <path
        d="M27.384 28.971C27.7388 28.7607 28.041 28.4716 28.2513 28.1036L28.6718 27.3809L30.6824 23.8985C30.8926 23.5306 30.9977 23.1232 30.9977 22.7158H23.7697L25.3077 25.7383L27.384 28.971Z"
        fill="#EA4335"
      />
      <path
        d="M19.5258 15.3566L23.1396 9.10152C22.7847 8.89126 22.3774 8.78613 21.9569 8.78613H17.0947C16.6742 8.78613 16.2668 8.9044 15.912 9.10152L19.5258 15.3566Z"
        fill="#00832D"
      />
      <path
        d="M23.7705 22.7158H15.2814L11.6676 28.971C12.0224 29.1812 12.4298 29.2863 12.8503 29.2863H26.2016C26.6221 29.2863 27.0295 29.1681 27.3843 28.971L23.7705 22.7158Z"
        fill="#2684FC"
      />
      <path
        d="M27.3447 15.7509L24.0068 9.96887C23.7966 9.60092 23.4943 9.31182 23.1395 9.10156L19.5258 15.3567L23.7703 22.7157H30.9847C30.9847 22.3083 30.8796 21.9009 30.6694 21.533L27.3447 15.7509Z"
        fill="#FFBA00"
      />
    </svg>
  );
}

export default IconGD;
