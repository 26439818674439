import React, { useContext, useRef, useState, useEffect } from 'react';
import Link from 'next/link';

import { Button } from 'reactstrap';
import Bin from './Bin';
import { useSidebar } from '../../context/SideBarContext';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TLanguageRegion, useGetLanguagesQuery } from '@root/graphql/generated/operations';

type SidebarProps = {
  isShowSidebarItems?: boolean;
  medias?: ImportedMedia[];
  mediaFolders?: ProjectFolder[];
  projectId?: string;
  userHasWritePermission?: boolean;
  handleChangeSelected?: (selectedMedia: ImportedMedia) => void;
  handleCreateMediaFolder?: (folderName: string, callback: Function) => void;
  handleAmendMedia?: (mediaId: string, folderId: string | null) => void;
  openModalConfirmDeleteMedia?: (media: ImportedMedia) => void;
  handleAmendMediaFolder?: (folderId: string, folderName: string) => void;
  handleArchiveMediaFolder?: (folder: ProjectFolder) => void;
};

const Sidebar = ({
  isShowSidebarItems = true,
  medias,
  mediaFolders,
  projectId,
  userHasWritePermission,
  handleChangeSelected,
  openModalConfirmDeleteMedia,
  handleCreateMediaFolder,
  handleAmendMedia,
  handleAmendMediaFolder,
  handleArchiveMediaFolder,
}: SidebarProps) => {
  const { mediasState, lastTranscribe, setListFileMedia, setAccessTokenGD, setLastTranscribe, onAmendMedia } =
    useSidebar();

  const [loadedPanelActive, setLoadedPanelActive] = useState(false);
  // const [listLanguageRegion, setListLanguageRegion] = useState<LanguageRegion[]>([]);
  const sidebarRef = React.createRef<HTMLElement>();

  const isOpen = loadedPanelActive;

  useEffect(() => {
    if (medias && mediaFolders) {
      const newMedias = [...medias];
      // mediaFolders.map((folder) => {
      //   folder.imported_media.map((media: any) => {
      //     newMedias.push(media);
      //   });
      // });
      setListFileMedia(newMedias);
    }
  }, [medias, mediaFolders]);

  // using class toggle instead of conditional rendering to enable animation
  useEffect(() => {
    if (isOpen) {
      sidebarRef.current && sidebarRef.current.classList.add('active');
    } else {
      sidebarRef.current && sidebarRef.current.classList.remove('active');
    }
  }, [sidebarRef, isOpen]);

  // handle click outside sidebar
  useEffect(() => {
    const handleClickOutsideSidebar: EventListener = (event: Event): void => {
      if (document.getElementById('modal-payment') || document.getElementById('modal-add-credit')) return;
      if (event.target && sidebarRef.current && !sidebarRef.current.contains(event.target as HTMLElement)) {
        setLoadedPanelActive(false);
      }
    };
    document.addEventListener('click', handleClickOutsideSidebar);
    return () => {
      document.removeEventListener('click', handleClickOutsideSidebar);
    };
  }, [sidebarRef]);

  const toggleVideo = () => {
    setLoadedPanelActive(!loadedPanelActive);
  };

  const handleMediaClicked = (media: ImportedMedia): void => {
    setLoadedPanelActive(false);

    if (handleChangeSelected) {
      handleChangeSelected(media);
    }
  };

  function getNewFolders() {
    return mediaFolders?.map((folder) => {
      const importedMedia = [];

      for (const media of folder.imported_media) {
        if (media.project_folder_id == folder.id) {
          importedMedia.push(media);
        }
      }
      return { ...folder, imported_media: importedMedia };
    });
  }

  return (
    <nav id="sidebar" ref={sidebarRef}>
      <ul className="list-unstyled sidebar-items">
        <li>
          <div>
            <div title="Back to dashboard" className="sidebar-icon sidebar-logo">
              <Link href="/">
                <a>
                  <img src="/logo.png" className="logo" alt="Storylines" />
                </a>
              </Link>
            </div>
            <div className="sidebar-text sidebar-top"></div>
          </div>
        </li>
        <React.Fragment>
          {isShowSidebarItems && (
            <li title="Bin of project media">
              <div onClick={toggleVideo} className={`sidebar-row ${loadedPanelActive ? 'selected' : ''}`}>
                <div className="sidebar-icon">
                  <Button color="link">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 100 100"
                      x="0px"
                      y="10px"
                      className="svg-inline--fa fa-file-upload fa-w-12 "
                      width="16"
                      height="16"
                    >
                      <path
                        fill="currentColor"
                        d="M42,82H58a8,8,0,0,0,8-8V70a4,4,0,0,0-8,0v4H42V70a4,4,0,0,0-8,0v4A8,8,0,0,0,42,82Z"
                      />
                      <path
                        fill="currentColor"
                        d="M97.79,52.73,84.53,12.94A16,16,0,0,0,69.35,2H30.65A16,16,0,0,0,15.47,12.94L2.21,52.73a2.89,2.89,0,0,0-.08.65A3.25,3.25,0,0,0,2,54V78A20,20,0,0,0,22,98H78A20,20,0,0,0,98,78V54a3.25,3.25,0,0,0-.13-.62A2.89,2.89,0,0,0,97.79,52.73ZM23.06,15.47A8,8,0,0,1,30.65,10h38.7a8,8,0,0,1,7.59,5.47L88.45,50H11.55ZM90,78A12,12,0,0,1,78,90H22A12,12,0,0,1,10,78V58H90Z"
                      />
                      <path fill="currentColor" d="M30,42H70a4,4,0,0,0,0-8H30a4,4,0,0,0,0,8Z" />
                      <path fill="currentColor" d="M34,26H66a4,4,0,0,0,0-8H34a4,4,0,0,0,0,8Z" />
                    </svg>
                  </Button>
                </div>
                <div className="sidebar-text">
                  <span>BIN</span>
                </div>
              </div>
            </li>
          )}
        </React.Fragment>

        <li className="sidebar-question-mark sidebar-row">
          <a href="https://storylines.crisp.help/en/" target="_blank" rel="noreferrer">
            <div className="sidebar-icon">
              <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <title>Help</title>

                <g>
                  <title>background</title>
                  <rect fill="none" id="canvas_background" height="102" width="102" y="-1" x="-1" />
                </g>
                <g>
                  <path
                    id="svg_1"
                    d="m50,9a41,41 0 1 1 -41,41a41,41 0 0 1 41,-41m0,-9a50,50 0 1 0 50,50a50,50 0 0 0 -50,-50z"
                  />
                  <path
                    id="svg_2"
                    d="m49.73,60.61l-3.65,0a1.12,1.12 0 0 1 -1.21,-1.15a37.71,37.71 0 0 1 0.74,-9.36a8.53,8.53 0 0 1 2.25,-4c1,-1.06 2.08,-2 3.16,-3s2.37,-2.1 3.59,-3.1c2.4,-2 2.11,-5.48 -0.89,-7.12a7,7 0 0 0 -9.8,3.45a13.7,13.7 0 0 0 -0.6,1.88c-0.24,0.87 -0.64,1.18 -1.54,1.07l-7.13,-0.86a1.26,1.26 0 0 1 -1.18,-1.52a14.61,14.61 0 0 1 12.1,-12.9a20.73,20.73 0 0 1 12.59,1.16a13.59,13.59 0 0 1 7.65,8a10.58,10.58 0 0 1 -1.66,10.56a37.87,37.87 0 0 1 -5.76,5.63c-0.67,0.58 -1.31,1.2 -2,1.77a4.52,4.52 0 0 0 -1.65,3.65c0,1.53 -0.1,3.05 -0.14,4.58a1.23,1.23 0 0 1 -1.35,1.35l-3.52,0l0,-0.09z"
                  />
                  <path id="svg_3" d="m54.89,71.5a5,5 0 1 1 -5,-5a5,5 0 0 1 5,5z" />
                </g>
              </svg>
            </div>
          </a>
        </li>
      </ul>
      <Bin
        loadedPanelActive={loadedPanelActive}
        mediaFolders={getNewFolders()}
        loaded={medias}
        // languages={(languagesData?.getLanguages?.languages as Language[]) || []}
        // languageRegions={listLanguageRegion}
        lastTranscribe={lastTranscribe}
        projectId={projectId}
        userHasWritePermission={userHasWritePermission}
        handleChangeSelected={handleMediaClicked}
        handleCreateMediaFolder={handleCreateMediaFolder}
        handleAmendMedia={(mediaId: string, folderId: string | null) => {
          onAmendMedia(mediaId, folderId);
          if (handleAmendMedia) handleAmendMedia(mediaId, folderId);
        }}
        handleAmendMediaFolder={handleAmendMediaFolder}
        openModalConfirmDeleteFolder={(folder: ProjectFolder) => {
          console.log('-------------- OPEN MODAL ----------------');
          console.log(folder);
          if (handleArchiveMediaFolder) {
            handleArchiveMediaFolder(folder);
          }
        }}
        openModalConfirmDeleteMedia={(media: ImportedMedia) => {
          if (openModalConfirmDeleteMedia) {
            openModalConfirmDeleteMedia(media);
          }
        }}
      />
      {isShowSidebarItems && (
        <div className="indication-icon" onClick={() => setLoadedPanelActive((prev) => !prev)}>
          <FontAwesomeIcon icon={faCaretRight} />
        </div>
      )}
    </nav>
  );
};

export default Sidebar;
