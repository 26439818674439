// @ts-nocheck

import React from 'react';
import loadScript from 'load-script';

// reference to
// https://developers.google.com/drive/picker/reference
// https://developers.google.com/drive/picker/guides/sample
// https://developers.google.com/identity/protocols/oauth2/scopes

const GOOGLE_API_KEY = 'AIzaSyBmMC5eprEA6Vd-Ur-dprfrgqPoNJ7DgwM';
const GOOGLE_APP_ID = 60401667946;
const GOOGLE_CLIENT_ID = '60401667946-5naqst1c9ljlakji9anpvekd03q62gva.apps.googleusercontent.com';
const GOOGLE_SCOPE = 'https://www.googleapis.com/auth/drive.readonly'; // separator is <space character>

type props = {
  onChange: (data: any) => void;
  onAuthenticate?: (token: string) => void;
  onAuthFailed: (data: any) => void;
};

export default class GoogleChooser extends React.Component<props> {
  tokenClient;
  accessToken;
  pickerInited;
  gisInited;
  scriptLoadingStarted;

  constructor(props) {
    super(props);

    this.pickerInited = false;
    this.gisInited = false;
    this.accessToken = null;
    this.scriptLoadingStarted = false;
  }

  componentDidMount(): void {
    if (this.isGoogleReady()) {
      this.onApiLoad();
      this.onGisLoad();
    } else if (!this.scriptLoadingStarted) {
      this.scriptLoadingStarted = true;
      loadScript('https://apis.google.com/js/api.js', this.onApiLoad.bind(this));
      loadScript('https://accounts.google.com/gsi/client', this.onGisLoad.bind(this));
    }
  }

  componentWillUnmount(): void {
    if (this.accessToken) window.google.accounts.oauth2.revoke(this.accessToken);
  }

  isGoogleReady() {
    return !!window.gapi;
  }

  isGoogleAuthReady() {
    return !!window.gapi.auth2;
  }

  isGooglePickerReady() {
    return !!window.google.picker;
  }

  onApiLoad() {
    window.gapi.load('client:picker', this.onPickerApiLoad.bind(this));
  }

  onGisLoad() {
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: GOOGLE_CLIENT_ID,
      scope: GOOGLE_SCOPE,
    });
    this.gisInited = true;
  }

  async onPickerApiLoad() {
    await window.gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest');
    this.pickerInited = true;
  }

  onChoose() {
    this.createPicker();
    if (!this.isGoogleReady() || !this.isGoogleAuthReady() || !this.isGooglePickerReady() || this.props.disabled)
      return;

    this.createPicker();
  }

  pickerCallback(data) {
    let url = 'nothing';
    if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
      const doc = data[google.picker.Response.DOCUMENTS][0];
      url = doc[google.picker.Document.URL];
    }
    const message = `You picked: ${url}`;
    console.log(message);

    this.props.onChange(data);
  }

  async getFile(fileId: string): void {
    const res = await window.gapi.client.drive.files.get({
      fileId: fileId,
      fields: '*',
    });

    console.log(res);
  }

  createPicker() {
    const showPicker = () => {
      const view = new window.google.picker.View(window.google.picker.ViewId.DOCS);
      view.setMimeTypes('video/mp4,video/mov,video/quicktime,audio/mp3,audio/mpeg,audio/wav,audio/x-wav');

      const picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .addView(view)
        .addView(new google.picker.DocsUploadView())
        .setOAuthToken(this.accessToken)
        .setAppId(GOOGLE_APP_ID)
        // .setDeveloperKey()
        .setCallback(this.pickerCallback.bind(this))
        .build();

      picker.setVisible(true);
    };

    this.tokenClient.callback = (response) => {
      if (response.error !== undefined) {
        this.props.onAuthFailed(response);
        throw response;
      }

      this.accessToken = response.access_token;
      this.props.onAuthenticate(this.accessToken);
      showPicker();
    };

    if (this.accessToken === null) {
      this.tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      this.tokenClient.requestAccessToken({ prompt: '' });
    }
  }

  render() {
    return (
      <div onClick={this.onChoose.bind(this)}>
        {this.props.children ? this.props.children : <button>Open google chooser</button>}
      </div>
    );
  }
}
