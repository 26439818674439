import React, { useEffect, useState, useContext } from 'react';
import { progressBarStore } from '../context/ProgressBarContext';

const Progress = (props: any) => {
  const { stack, incrementProgress } = useContext(progressBarStore);
  const defaultProps = {
    className: '',
    color: '#5FFFE2',
    height: 2,
    hideDelay: 0.4,
    percent: 0,
    speed: 0.4,
    style: {},
    predictiveSpeed: 10,
  };
  props = {
    ...defaultProps,
    ...props,
  };

  // const [desiredPercent, setDesiredPercent] = useState<number>(percent);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setDesiredPercent(current =>
  //       current < upTo ? current + props.predictiveSpeed*0.2 : current
  //     );
  //   }, 200);
  //   return () => clearInterval(interval);
  // }, [upTo, props.predictiveSpeed]);

  // useEffect(() => {
  //     setDesiredPercent(percent);
  // }, [percent])

  useEffect(() => {
    const interval = setInterval(() => {
      if (stack.length) incrementProgress(props.predictiveSpeed * 0.2);
    }, 200);
    return () => clearInterval(interval);
  }, [stack, incrementProgress]);

  let percent = 100;
  if (stack.length) {
    percent = 0;
    stack.forEach(({ estimatedPercent }) => {
      percent += estimatedPercent;
    });
    percent /= stack.length;
  }
  //const percent = stack.reduce(({identifier, estimatedPercent}) => {console.log(identifier)}, 0)

  const containerStyle = {
    opacity: percent < 100 ? 1 : 0,
    WebkitTransition: `${props.speed}s opacity`,
    transition: `${props.speed}s opacity`,
    WebkitTransitionDelay: `${percent < 100 ? 0 : props.hideDelay}s`,
    transitionDelay: `${percent < 100 ? 0 : props.hideDelay}s`,
  };

  const barStyle = {
    display: 'inline-block',
    position: 'fixed',
    top: 0,
    left: 0,
    width: `${percent}%`,
    maxWidth: '100% !important',
    height: `${props.height}px`,
    boxShadow: '1px 1px 1px rgba(0,0,0,0.4)',
    borderRadius: '0 1px 1px 0',
    WebkitTransition: `${props.speed}s width, ${props.speed}s background-color`,
    transition: `${props.speed}s width, ${props.speed}s background-color`,
    ...props.style,
  };

  if (props.color === 'rainbow') {
    barStyle.backgroundImage =
      props.style.backgroundImage || 'linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #FF2D55)';
    barStyle.backgroundSize = props.style.backgroundSize || `100vw ${props.height}px`;
  } else {
    barStyle.backgroundColor = props.style.backgroundColor || props.color;
  }
  barStyle.zIndex = 9999;

  return (
    <div className={props.className} style={containerStyle}>
      <div className={props.className.length ? `${props.className}__bar` : ''} style={barStyle}></div>
    </div>
  );
};

export default Progress;
