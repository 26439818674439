import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Modal } from 'reactstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProfileTab, { icon as ProfileTabIcon } from './ProfileTab';
import InviteTab, { icon as InviteTabIcon } from './InviteTab';
import LoginReferenceTab, { icon as LoginReferenceTabIcon } from './LoginReferenceTab';
import ConnectAppTab, { icon as ConnectAppTabIcon } from './ConnectAppTab';
import CloseAccountTab, { icon as CloseAccountTabIcon } from './CloseAccountTab';

import { useGetUserProfileLazyQuery } from '@root/graphql/generated/operations';

type ModalProfileProps = {
  toggle: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
};

const ModalProfile = ({ isOpen, toggle }: ModalProfileProps) => {
  const [userProfile, setUserProfile] = useState<User>();
  const [activeTab, setActiveTab] = useState(0);
  const [tabs, setTabs] = useState([
    { id: 0, label: 'Profile', icon: <ProfileTabIcon /> },
    { id: 1, label: 'Login References', icon: <InviteTabIcon /> },
    // { id: 2, label: 'Invite link/ free credit', icon: <LoginReferenceTabIcon /> },
    // { id: 3, label: 'Connected App', icon: <ConnectAppTabIcon /> },
    { id: 4, label: 'Close Account', icon: <CloseAccountTabIcon /> },
  ]);

  const [getUserData, { loading: loadingUserData, data: userData }] = useGetUserProfileLazyQuery({
    // fetchPolicy: 'network-only',
    onCompleted: (data) => {
      console.log(data);
      setUserProfile(data.getUserProfile);
    },
  });

  useEffect(() => {
    if (isOpen) {
      getUserData();
    }
  }, [isOpen]);

  return (
    <Modal id="modal-profile" isOpen={isOpen} fade={false} toggle={() => toggle(!isOpen)} className="">
      <div className="d-flex justify-content-between">
        <span className="txt-title">Profile Settings</span>
        <span style={{ cursor: 'pointer', color: '#828282' }} onClick={() => toggle(!isOpen)}>
          <FontAwesomeIcon icon={faTimes} width={16} height={16} />
        </span>
      </div>

      <div className="d-flex" style={{ marginTop: 16 }}>
        <div style={{ width: 180 }}>
          <React.Fragment>
            {tabs.map((tab) => (
              <div
                key={tab.id}
                className={`d-flex align-items-center ${activeTab === tab.id ? 'tab-active' : 'tab-default '}`}
                onClick={() => setActiveTab(tab.id)}
              >
                <div className="d-flex align-items-center justify-content-center con-icon-tab">{tab.icon}</div>
                <span className="txt-title-tab">{tab.label}</span>
              </div>
            ))}
          </React.Fragment>
        </div>

        <React.Fragment>
          {activeTab === 0 && <ProfileTab userProfile={userProfile} loadingUserData={loadingUserData} />}
          {activeTab === 1 && <LoginReferenceTab user={userProfile} />}
          {activeTab === 2 && <InviteTab userProfile={userProfile} />}
          {activeTab === 3 && <ConnectAppTab />}
          {activeTab === 4 && <CloseAccountTab />}
        </React.Fragment>
      </div>
    </Modal>
  );
};

export default ModalProfile;
