import React from 'react';

function IconInDD() {
  return (
    <svg width="53" height="40" viewBox="0 0 53 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.64228 8.57756L15.7838 3.88437C16.0046 3.80557 16.239 3.77211 16.4731 3.78598C16.7071 3.79985 16.9359 3.86077 17.1459 3.96509L24.86 7.79706C25.2642 7.99791 25.5748 8.34765 25.7264 8.77275L33.024 29.2069C33.1796 29.6425 33.1557 30.122 32.9577 30.54C32.7597 30.958 32.4038 31.2802 31.9682 31.4357L13.1993 38.1386C12.7638 38.2942 12.2843 38.2703 11.8663 38.0723C11.4483 37.8743 11.1261 37.5184 10.9705 37.0828L1.5865 10.8063C1.43095 10.3708 1.4548 9.89127 1.6528 9.47329C1.85079 9.05531 2.20672 8.73311 2.64228 8.57756Z"
        fill="#4F206D"
        stroke="white"
        strokeOpacity="0.56"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.096 15.7216L21.763 20.3895C21.9082 20.796 21.8859 21.2436 21.7011 21.6338C21.5163 22.0239 21.184 22.3247 20.7775 22.4699L20.5583 22.5481C20.1518 22.6932 19.7043 22.6708 19.3143 22.486C18.9242 22.3011 18.6236 21.969 18.4784 21.5625L18.4003 21.3438C18.2552 20.9373 18.2774 20.4898 18.4621 20.0997C18.6468 19.7096 18.9789 19.4089 19.3853 19.2636L19.6045 19.1853C19.7565 19.131 19.9117 19.1005 20.0649 19.0923L19.3074 16.9714L16.1445 18.4851L17.5788 22.5012C17.724 22.9077 17.7017 23.3552 17.517 23.7453C17.3323 24.1354 17.0002 24.4361 16.5938 24.5814L16.3746 24.6597C15.9681 24.8049 15.5205 24.7826 15.1303 24.5978C14.7402 24.413 14.4394 24.0807 14.2942 23.6742L14.216 23.4551C14.0709 23.0485 14.0933 22.601 14.2781 22.211C14.463 21.8209 14.7952 21.5203 15.2016 21.3751L15.4208 21.2969C15.5728 21.2426 15.7275 21.2122 15.8812 21.2039L14.6178 17.6664C14.5434 17.4581 14.6466 17.2106 14.8624 17.1082L19.376 14.9487C19.5852 14.8486 19.8156 14.9366 19.8898 15.1445L20.096 15.7216Z"
        fill="#FF5630"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5568 1.40303L44.1334 4.6281C44.3615 4.68234 44.5763 4.78202 44.7649 4.92116C44.9536 5.06031 45.1123 5.23608 45.2315 5.43795L49.6121 12.8543C49.8415 13.243 49.9114 13.7055 49.8069 14.1445L44.7922 35.2553C44.6853 35.7052 44.404 36.0943 44.0103 36.3369C43.6165 36.5795 43.1425 36.6558 42.6925 36.5489L23.3022 31.9428C22.8522 31.8359 22.4631 31.5546 22.2205 31.1609C21.9779 30.7671 21.9017 30.2931 22.0085 29.8431L28.4571 2.69665C28.564 2.24667 28.8452 1.85758 29.239 1.61498C29.6328 1.37238 30.1068 1.29614 30.5568 1.40303Z"
        fill="#4F206D"
        stroke="white"
        strokeOpacity="0.58"
      />
      <g clipPath="url(#clip0)">
        <rect width="13" height="13" transform="translate(32.0039 11) rotate(13.36)" fill="white" fillOpacity="0.01" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.3619 17.7258C41.4417 17.7108 41.5206 17.714 41.5949 17.7316C41.8436 17.7907 42.0302 18.0109 41.9755 18.2412L41.1627 21.6636C41.108 21.8939 40.8418 22.0066 40.5941 21.9478C40.5198 21.9301 40.4469 21.8972 40.3819 21.8473L39.1831 20.7743L39.8083 18.1419L41.3619 17.7258ZM33.6869 15.2968L38.5817 16.4593C39.1224 16.5877 39.4663 17.0852 39.3509 17.5711L38.5171 21.0821C38.4017 21.568 37.8708 21.8578 37.3301 21.7293L32.4352 20.5668C31.8945 20.4384 31.5506 19.9409 31.666 19.455L32.4997 15.9446C32.6152 15.4581 33.1462 15.1683 33.6869 15.2968Z"
          fill="#FFAB00"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="13" height="13" fill="white" transform="translate(32.0039 11) rotate(13.36)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconInDD;
