import React from 'react';

function IconLocal() {
  return (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="19.4018" cy="19.4018" r="19.4018" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5236 16.9337L16.4952 18.9779C16.2986 19.176 16.1882 19.4438 16.1882 19.7229C16.1882 20.002 16.2986 20.2699 16.4952 20.468C16.5918 20.5657 16.7069 20.6433 16.8337 20.6963C16.9605 20.7492 17.0965 20.7765 17.2339 20.7765C17.3713 20.7765 17.5074 20.7492 17.6342 20.6963C17.761 20.6433 17.876 20.5657 17.9726 20.468L19.3368 19.0921L20.7497 20.5166C20.8462 20.6144 20.9612 20.6921 21.088 20.7452C21.2147 20.7982 21.3508 20.8256 21.4882 20.8257C21.6256 20.8258 21.7617 20.7986 21.8885 20.7457C22.0154 20.6929 22.1304 20.6153 22.2271 20.5177C22.4231 20.3195 22.533 20.0521 22.5332 19.7734C22.5334 19.4947 22.4238 19.2271 22.2282 19.0286L20.1501 16.9347C20.0439 16.8269 19.9173 16.7412 19.7778 16.6827C19.6382 16.6243 19.4884 16.5941 19.3371 16.594C19.1857 16.5939 19.0359 16.6239 18.8962 16.6822C18.7566 16.7405 18.6299 16.826 18.5236 16.9337Z"
        fill="#4F206D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3036 17.6602L18.3047 28.2208C18.3047 28.8098 18.7785 29.2868 19.3622 29.2857C19.947 29.2857 20.4198 28.8098 20.4198 28.2208L20.4187 17.6591C20.4187 17.0722 19.9449 16.5952 19.3612 16.5952C18.7774 16.5952 18.3026 17.0722 18.3036 17.6602Z"
        fill="#4F206D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0654 12.8738C14.7938 12.8293 14.519 12.807 14.2437 12.8071C11.2773 12.8082 8.78577 15.1358 8.78577 18.1805C8.78577 21.2569 11.308 23.998 14.1792 23.998H17.226V21.8903H14.1792C12.4269 21.8903 10.885 20.1317 10.885 18.1805C10.885 16.3002 12.4385 14.9159 14.1676 14.9159H14.1803C14.5917 14.9159 14.9058 14.9698 15.2061 15.0745L15.3859 15.1411C16.0257 15.4034 16.3112 14.881 16.3112 14.881L16.4699 14.5986C17.2419 13.1741 18.7975 12.382 20.3997 12.3577C21.4625 12.3683 22.4852 12.7651 23.2769 13.4742C24.0687 14.1832 24.5755 15.1561 24.7028 16.2114L24.7515 16.5709C24.7515 16.5709 24.8265 17.1261 25.4547 17.1261C25.4685 17.1261 25.4674 17.1314 25.479 17.1314H25.7477C26.949 17.1314 27.8374 18.1456 27.8374 19.4136C27.8374 20.69 26.7936 21.8903 25.6482 21.8903H21.4562V23.998H25.6482C27.9325 23.998 29.9366 21.8354 29.9366 19.4136C29.9366 17.2996 28.5491 15.5398 26.618 15.1052C25.8703 12.2424 23.3893 10.2912 20.3933 10.25C18.3047 10.2722 16.2679 11.2028 15.0654 12.8738Z"
        fill="#4F206D"
      />
    </svg>
  );
}

export default IconLocal;
