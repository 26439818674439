import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

type LoginReferenceTabProps = {
  user?: User;
};

const LoginReferenceTab = (props: LoginReferenceTabProps) => {
  const { user } = props;
  const { loginWithPopup } = useAuth0();

  function connectGoogleAccount() {
    loginWithPopup({
      prompt: 'login',
      connection: 'google-oauth2',
    });
  }

  function isUserUsingGoogleAuth() {
    return user && user?.identities && user?.identities?.indexOf('google-oauth2') !== -1;
  }

  function isUserUsingEmailAuthentication() {
    return user && user?.identities && user?.identities?.indexOf('auth0') !== -1;
  }

  function changePassword() {
    if (!user?.email) return;

    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        client_id: 'q7JBfnWssrTNnnxofwCQ35AF4zlQvniq',
        email: user?.email,
        connection: 'Username-Password-Authentication',
      }),
    };

    fetch(`https://${process.env.NEXT_PUBLIC_AUTH0_DOMAIN}/dbconnections/change_password`, options)
      .then((res) => console.log(res))
      .catch((err) => console.log({ err }));
  }

  return (
    <div className="con-content-right">
      <div>EMAIL ADDRESS</div>
      <div>{user?.email}</div>

      <div style={{ padding: '12px 0' }}></div>

      <div>GOOGLE ACCOUNT</div>
      <div>
        Status:
        {isUserUsingGoogleAuth() ? (
          <span style={{ color: '#43f800' }}> Connected</span>
        ) : (
          <span style={{ color: '#f94747' }}> Not connected</span>
        )}
      </div>
      {!isUserUsingGoogleAuth() && <div onClick={connectGoogleAccount}>Connect your Google account</div>}

      <div style={{ padding: '12px 0' }}></div>

      <div>PASSWORD</div>
      <div>
        Status:
        {isUserUsingEmailAuthentication() ? (
          <span style={{ color: '#43f800' }}> Password has been set</span>
        ) : (
          <span style={{ color: '#f94747' }}> No password has been set</span>
        )}
      </div>

      <div style={{ padding: '12px 0' }}></div>

      {/* {isUserUsingEmailAuthentication() && (
        <div onClick={changePassword}>
          <span style={{ color: '#e93d9c', cursor: 'pointer' }}>CHANGE PASSWORD</span>
        </div>
      )} */}
    </div>
  );
};

export const icon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0108 14.6011C17.4248 14.6011 17.7608 14.2651 17.7608 13.8511V11.9991C17.7608 11.5851 17.4248 11.2491 17.0108 11.2491H11.3188C10.9948 10.1821 10.0128 9.39815 8.84076 9.39815C7.40676 9.39815 6.23976 10.5651 6.23976 11.9991C6.23976 13.4341 7.40676 14.6011 8.84076 14.6011C10.0128 14.6011 10.9948 13.8171 11.3188 12.7491H13.4308V13.8511C13.4308 14.2651 13.7668 14.6011 14.1808 14.6011C14.5948 14.6011 14.9308 14.2651 14.9308 13.8511V12.7491H16.2608V13.8511C16.2608 14.2651 16.5968 14.6011 17.0108 14.6011ZM7.66576 1.99915H16.3348C19.7228 1.99915 21.9998 4.37715 21.9998 7.91615V16.0831C21.9998 19.6221 19.7228 21.9991 16.3338 21.9991H7.66576C4.27676 21.9991 1.99976 19.6221 1.99976 16.0831V7.91615C1.99976 4.37715 4.27676 1.99915 7.66576 1.99915ZM7.73886 12C7.73886 11.392 8.23386 10.898 8.84086 10.898C9.44786 10.898 9.94286 11.392 9.94286 12C9.94286 12.607 9.44786 13.101 8.84086 13.101C8.23386 13.101 7.73886 12.607 7.73886 12Z"
        fill="white"
      />
    </svg>
  );
};

export default LoginReferenceTab;
