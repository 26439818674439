import React from 'react';

type TimeCodeSVGProps = {
  color?: string;
  width?: number;
  height?: number;
};

export default function TimeCodeSVG(props: TimeCodeSVGProps) {
  let { color, width, height } = props;
  color = color ? color : '#fff';
  width = width ? width : 16;
  height = height ? height : 16;

  return (
    <svg viewBox="0 0 16 17" width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <g>
        <g stroke="null" id="svg_9">
          <path
            id="svg_1"
            fill={color}
            d="m4.88167,13.56575l0.3587,0.21074l0.81594,-1.48882l-0.35871,-0.21074l-0.81592,1.48882z"
          />
          <path
            id="svg_2"
            fill={color}
            d="m2.80181,11.28414l0.20839,0.36866l1.42653,-0.86428l-0.2084,-0.36877l-1.42652,0.86439z"
          />
          <path
            id="svg_3"
            fill={color}
            d="m6.00527,4.58469l-0.83568,-1.4767l-0.35598,0.21593l0.83567,1.47668l0.35599,-0.21592z"
          />
          <path
            id="svg_4"
            fill={color}
            d="m7.72667,12.74847l0.01092,1.71175l0.41278,-0.00286l-0.01091,-1.71175l-0.41279,0.00286z"
          />
          <path
            id="svg_5"
            fill={color}
            d="m4.40828,6.10317l-1.43775,-0.84463l-0.2037,0.37166l1.43775,0.84463l0.2037,-0.37166z"
          />
          <path
            id="svg_6"
            fill={color}
            d="m3.73251,8.19396l-1.65372,0.00685l0.00165,0.42774l1.65372,-0.00685l-0.00165,-0.42774z"
          />
          <path
            id="svg_7"
            fill={color}
            d="m9.7395,12.28855l0.81596,1.48849l0.35867,-0.21074l-0.81596,-1.48849l-0.35867,0.21074z"
          />
          <path
            id="svg_8"
            fill={color}
            d="m13.58799,2.68811c-1.49969,-1.55264 -3.49335,-2.40752 -5.61382,-2.40752c-2.12064,0 -4.11433,0.85489 -5.61385,2.40752c-1.49952,1.55244 -2.32526,3.61649 -2.32526,5.81199c0,2.19529 0.82574,4.25934 2.32526,5.81175c1.49952,1.55251 3.49321,2.40757 5.61385,2.40757c2.12047,0 4.11413,-0.85505 5.61382,-2.40757c1.49936,-1.55241 2.32531,-3.61646 2.32531,-5.81175c0,-2.1955 -0.82595,-4.25955 -2.32531,-5.81199zm0.18658,5.58933l-0.00176,0.42772l-1.65388,-0.00676l0.00187,-0.42772l1.65377,0.00676zm-0.87339,-2.94175l0.20371,0.37167l-1.43797,0.84464l-0.2036,-0.37167l1.43786,-0.84464zm-2.19957,-2.15066l0.35603,0.2159l-0.83572,1.47667l-0.35614,-0.21571l0.83583,-1.47687zm2.12083,10.33436c-1.2951,1.341 -3.01685,2.07943 -4.84845,2.07943c-1.83164,0 -3.55332,-0.73842 -4.84841,-2.07943c-1.29527,-1.34056 -2.00844,-3.12315 -2.00844,-5.01929c0,-1.89629 0.71317,-3.67874 2.00844,-5.01973c1.29509,-1.34079 3.01677,-2.07933 4.84841,-2.07933c0.01363,0 0.02726,0.00058 0.0407,0.00078l0,1.49504l0,5.60304l5.8741,3.59601c-0.29212,0.51297 -0.64892,0.99156 -1.06635,1.42348z"
          />
        </g>
      </g>
    </svg>
  );
}
