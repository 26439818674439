import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { progressBarStore } from '../../../context/ProgressBarContext';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
// import { faEdit } from '@fortawesome/free-regular-svg-icons';
// import ModalUpdateProfile from '../ModalUpdateProfile';

// import { useGenerateCustomerPortalLazyQuery } from '@root/graphql/generated/operations';

type ProfileTabProps = {
  userProfile?: User;
  loadingUserData?: boolean;
};

const ProfileTab = ({ userProfile, loadingUserData }: ProfileTabProps) => {
  const router = useRouter();

  // const [getLinkCustomerPortal, { loading, refetch: refetchLink }] = useGenerateCustomerPortalLazyQuery({
  //   notifyOnNetworkStatusChange: false,
  //   fetchPolicy: 'no-cache',
  //   onCompleted: (data) => {
  //     // console.log(data)
  //     completeProgress('loadingLinkCustomerPortal');
  //     router.push(data.generateCustomerPortal);
  //   },
  // });

  const { pushProgress, completeProgress } = useContext(progressBarStore);
  const [isOpenUpdateProfile, setIsOpenUpdateProfile] = useState(false);

  // useEffect(() => {
  //   if (loading) pushProgress('loadingLinkCustomerPortal');
  // }, [loading]);

  return (
    <div className="con-content-right">
      <div style={{ marginBottom: 18 }} className="d-flex flex-column align-items-start">
        <span>YOUR PROFILE PIC</span>

        <img src={userProfile?.avatar} className="img-avatar" />

        {/* <div style={{ position: 'relative', left: 12 }}>
          <span className="btn-edit-avatar" style={{ marginRight: 15 }}>
            <FontAwesomeIcon icon={faTrashAlt} width={12} height={12} />
          </span>
          <span className="btn-edit-avatar">
            <FontAwesomeIcon onClick={() => setIsOpenUpdateProfile(true)} icon={faEdit} width={12} height={12} />
            <ModalUpdateProfile isOpen={isOpenUpdateProfile} toggle={setIsOpenUpdateProfile} />
          </span>
        </div> */}
      </div>

      <div className="d-flex">
        <div className="d-flex flex-column" style={{ flex: 1 }}>
          <span className="txt-title">YOUR NAME</span>
          <span className="txt-content">{userProfile?.full_name}</span>

          <span className="txt-title" style={{ marginTop: 30 }}>
            YOUR EMAIL ADDRESS
          </span>
          <span className="txt-content">{userProfile?.email}</span>

          <span className="txt-title" style={{ marginTop: 30 }}>
            CURRENT PLAN
          </span>

          {loadingUserData ? (
            <div className="loader-custom">Loading...</div>
          ) : (
            <span className="txt-content">{userProfile?.planType?.label}</span>
          )}
        </div>
      </div>

      {/* <div style={{ marginTop: 20 }}>
        <span className="txt-plan" onClick={() => getLinkCustomerPortal()}>
          UPGRADE PLAN
        </span>
        <span className="txt-plan" onClick={() => getLinkCustomerPortal()}>
          PURCHASE CREDIT
        </span>
        <span className="txt-plan" onClick={() => getLinkCustomerPortal()}>
          CUSTOMER PORTAL
        </span>
      </div> */}
    </div>
  );
};

export const icon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.294 7.29105C17.294 10.2281 14.9391 12.5831 12 12.5831C9.0619 12.5831 6.70601 10.2281 6.70601 7.29105C6.70601 4.35402 9.0619 2 12 2C14.9391 2 17.294 4.35402 17.294 7.29105ZM12 22C7.66237 22 4 21.295 4 18.575C4 15.8539 7.68538 15.1739 12 15.1739C16.3386 15.1739 20 15.8789 20 18.599C20 21.32 16.3146 22 12 22Z"
        fill="white"
      />
    </svg>
  );
};

export default ProfileTab;
