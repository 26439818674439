import React, { useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';

import IconLocal from '../svg/IconLocal';
import IconDB from '../svg/IconDB';
import IconGD from '../svg/IconGD';
import IconInDD from '../svg/IconInDD';
import GooglePicker from '../upload-video/GooglePicker';
import { TypeInputFile } from '@root/graphql/generated/operations';
import { useSidebar } from '../../context/SideBarContext';

declare const Dropbox: Dropbox;
interface Dropbox {
  choose(options: DropboxChooseOptions): void;
}
interface DropboxChooseOptions {
  success(files: DropboxFile[]): void;
  cancel?(): void;
  linkType: 'direct' | 'preview';
  multiselect: boolean;
  extensions?: string[];
}
interface DropboxFile {
  name: string;
  link: string;
  bytes: number;
  icon: string;
  thumbnailLink?: string;
  isDir: boolean;
}

type UploadVideoProp = {};

const UploadVideo = (props: UploadVideoProp) => {
  const inputFileRef = useRef<any>(null);
  const { handleUploadFilesWithMux, handleLoadFiles, setAccessTokenGD, useMuxService, accessTokenGD } = useSidebar();
  const shouldUseMuxService = useMuxService;

  const {
    getRootProps: getRootPropsPublish,
    getInputProps: getInputPropsPublish,
    isDragActive,
  } = useDropzone({
    onDrop: async (acceptedFiles) => {
      onClickLocal(acceptedFiles);
    },
    accept: '.mp4,.mov,.mp3,.wav',
    noClick: true,
  });

  async function onClickLocal(acceptedFiles: any) {
    const tempAcceptedFiles: FileMetadata[] = [];

    for (const file of acceptedFiles) {
      const duration = await getDuration(file);
      tempAcceptedFiles.push({
        type: TypeInputFile.Local,
        file: file,
        fileName: file.name,
        durationInSeconds: duration,
        fileSize: file.size,
      });
    }

    if (shouldUseMuxService) {
      handleUploadFilesWithMux(tempAcceptedFiles);
    } else {
      handleLoadFiles(tempAcceptedFiles);
    }

    inputFileRef.current.value = null;
  }

  function onClickDropbox() {
    const options: DropboxChooseOptions = {
      success: async (files) => {
        const listTempDB: FileMetadata[] = [];

        if (shouldUseMuxService) {
          for (const file of files) {
            // const dropboxFile = (callback: (percentage: number) => void) =>
            //   getFileByStream(file.link, file.name, undefined, callback);

            // const duration = await getDuration(dropboxFile);

            listTempDB.push({
              type: TypeInputFile.Dropbox,
              fileName: file.name,
              fileSize: file.bytes,
              // durationInSeconds: duration,
              // file: dropboxFile,
              // getFile: dropboxFile,
              linkToFile: file.link,
            });
          }

          handleUploadFilesWithMux(listTempDB);
          return;
        }

        for (const file of files) {
          listTempDB.push({
            type: TypeInputFile.Dropbox,
            fileName: file.name,
            fileSize: file.bytes,
            linkToFile: file.link,
          });
        }

        handleLoadFiles(listTempDB);
      },
      cancel: () => {},
      linkType: 'direct',
      multiselect: true,
      extensions: ['.mp4', '.mov', '.mp3', '.wav'],
    };

    Dropbox.choose(options);
  }

  function onClickGoogle(data: any) {
    if (data.action !== 'picked') return;

    const listTempGD: FileMetadata[] = [];

    if (shouldUseMuxService) {
      for (const file of data.docs) {
        const url = `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`;
        // const driveFile = (callback: (percentage: number) => void) =>
        //   getFileByStream(url, file.name, file.mimeType, callback);

        listTempGD.push({
          type: TypeInputFile.Drive,
          fileName: file.name,
          fileSize: file.sizeBytes,
          // durationInSeconds: file.duration as number,
          // getFile: driveFile,
          linkToFile: url,
          accessToken: accessTokenGD,
        });
      }

      handleUploadFilesWithMux(listTempGD);
      return;
    }

    for (const file of data.docs) {
      listTempGD.push({
        type: TypeInputFile.Drive,
        fileName: file.name,
        fileSize: file.sizeBytes,
        linkToFile: file.id,
      });
    }

    handleLoadFiles(listTempGD);
  }

  function getDuration(file: File) {
    return new Promise<number>((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        resolve(Math.round(video.duration));
      };

      video.onerror = function (err) {
        reject(err);
      };

      video.src = URL.createObjectURL(file);
    });
  }

  async function getFileByStream(
    url: string,
    fileName: string,
    type?: string,
    callback?: (percentage: number) => void,
  ) {
    if (typeof window === 'undefined') return; // make sure we are in the browser

    const headers = new Headers();

    if (accessTokenGD) {
      headers.append('Authorization', 'Bearer ' + accessTokenGD);
    }

    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });

    const contentLength = response.headers.get('content-length') || '0';
    const total = parseInt(contentLength, 10);
    let loaded = 0;

    function progress({ loaded, total }: any) {
      const percentage = Math.round((loaded / total) * 100);
      if (callback) callback(percentage);
    }

    const res = new Response(
      new ReadableStream({
        async start(controller) {
          if (!response.body) {
            controller.close();
            return;
          }

          const reader = response.body.getReader();

          for (;;) {
            const { done, value } = await reader.read();
            if (done) break;

            loaded += value.byteLength;
            progress({ loaded, total });
            controller.enqueue(value);
          }

          controller.close();
        },
      }),
    );

    const data = await res.blob();

    const metadata = {
      type: data.type,
      ...(type ? { type } : {}),
    };

    return new File([data], fileName, metadata);
  }

  function onAuthenticateGD(token: string) {
    setAccessTokenGD(token);
  }

  return (
    <div className="p-0 mr-5 mt-3">
      <div {...getRootPropsPublish()}>
        <div
          className="d-flex align-items-center justify-content-center con-drag-drop"
          style={{ background: isDragActive ? 'rgba(255, 255, 255, 0.1)' : '' }}
        >
          <IconInDD />
          <div className="ml-2">
            <div className="txt-line-up">Drag and drop your audio & video files here</div>
            <div className="txt-line-down">Files supported: mp4, mov, mp3, & more. Max. file size: 100GB</div>
          </div>
        </div>
        <input {...getInputPropsPublish()} />
      </div>
      <div className="d-flex flex-row mt-2">
        <a className="upload-icon dropbox-upload mr-2" onClick={() => inputFileRef.current.click()}>
          <IconLocal />
          <input
            ref={inputFileRef}
            multiple
            accept=".mp4,.mov,.mp3,.wav"
            type="file"
            style={{ display: 'none' }}
            onChange={(e) => onClickLocal(e.target.files)}
          />
        </a>
        <a className="upload-icon dropbox-upload mr-2" onClick={onClickDropbox}>
          <IconDB />
        </a>
        <GooglePicker
          onChange={onClickGoogle}
          onAuthenticate={onAuthenticateGD}
          onAuthFailed={(data: any) => console.log('on auth failed:', data)}
        >
          <a className="upload-icon dropbox-upload mr-2">
            <IconGD />
          </a>
        </GooglePicker>
      </div>
      <div style={{ background: 'rgba(255, 255, 255, 0.5)', width: '100%', height: 1, margin: '10px 0' }} />
    </div>
  );
};

export default UploadVideo;
