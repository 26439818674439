import React, { useState, useRef, useMemo, useEffect, useContext } from 'react';
import { useDrop, useDrag, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { useTextInput, useTextInputSettable } from '../../common/custom-hook';

import { Button, Collapse, Progress, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSearch, faPlus, faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt, faFolder, faEdit } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import UploadVideo from '../upload-video/UploadVideo';
import IconFile from '../svg/IconFile';
import ModalMediaLanguage from '../Modal/ModalMediaLanguage';
import IconTranscribe from '../svg/IconTranscribe';
import IconRetry from '../svg/IconRetry';
import ModalTranslate from '../Modal/ModalTranslate';
import ModalInsufficientCredit from '../Modal/ModalInsufficientCredit';
import ModalPayment from '../Modal/ModalPayment';
import ModalAddCredit from '../Modal/ModalAddCredit';

import { useSidebar } from '../../context/SideBarContext';

import { TypeInputFile, MediaStatus } from '@root/graphql/generated/operations';

type BinProps = {
  mediaFolders?: ProjectFolder[];
  loaded?: ImportedMedia[];
  projectId?: string;
  // languages?: Language[];
  // languageRegions?: LanguageRegion[];
  lastTranscribe?: LanguageRegion;
  userHasWritePermission?: boolean;
  loadedPanelActive?: boolean;
  handleChangeSelected: (media: ImportedMedia) => void;
  openModalConfirmDeleteMedia?: (media: ImportedMedia) => void;
  handleCreateMediaFolder?: (folderName: string, callback: Function) => void;
  handleAmendMedia?: (mediaId: string, folderId: string | null) => void;
  handleAmendMediaFolder?: (folderId: string, folderName: string) => void;
  openModalConfirmDeleteFolder?: (folder: ProjectFolder) => void;
};

enum ItemTypes {
  MEDIA = 'MEDIA',
}

export default function Bin({
  mediaFolders,
  loaded,
  projectId,
  // languages,
  // languageRegions,
  lastTranscribe,
  loadedPanelActive,
  userHasWritePermission,
  handleChangeSelected,
  openModalConfirmDeleteMedia,
  openModalConfirmDeleteFolder,
  handleCreateMediaFolder,
  handleAmendMedia,
  handleAmendMediaFolder,
}: BinProps) {
  const { handleLoadFiles } = useSidebar();

  const search = useTextInput('');
  const inputCreateFolder = useTextInputSettable('');
  // const whisperLanguageRegion = useMemo(() => {
  //   const foundService = languageRegions?.find((item) => item.defaultSpeechApi === '7');
  //   return foundService;
  // }, [languageRegions]);

  function open(media: ImportedMedia) {
    if (openModalConfirmDeleteMedia) {
      openModalConfirmDeleteMedia(media);
    }
  }

  function createMediaProject() {
    if (handleCreateMediaFolder && typeof handleCreateMediaFolder === 'function') {
      handleCreateMediaFolder(inputCreateFolder.value, clearInputText);
    }
  }

  function clearInputText() {
    inputCreateFolder.setvalue('');
  }

  const renderMedias = (loaded: ImportedMedia[] | undefined) => {
    if (!loaded || !loaded.length) return null;

    return (
      loaded
        // .filter((video) => (!search.value ? true : new RegExp(search.value, 'gi').test(video.name || '')))
        .map((video, index) => (
          <DraggableMedia
            key={'media-not-in-folder-' + index}
            media={video}
            lastTranscribe={lastTranscribe}
            index={index}
            userHasWritePermission={userHasWritePermission}
            handleChangeSelected={handleChangeSelected}
            open={open}
          />
        ))
    );
  };

  const renderFolders = () => {
    if (!mediaFolders) return null;

    return mediaFolders
      .filter((folder) => !folder.archived)
      .filter((folder) => (!search.value ? true : new RegExp(search.value, 'gi').test(folder.name || '')))
      .map((folder, folderIdx) => (
        <DroppableFolder
          key={'folder-media-' + folderIdx}
          userHasWritePermission={userHasWritePermission}
          folder={folder}
          index={folderIdx}
          renderMedias={renderMedias}
          handleAmendMedia={handleAmendMedia}
          handleAmendMediaFolder={handleAmendMediaFolder}
          openModalConfirmDeleteFolder={openModalConfirmDeleteFolder}
        />
      ));
  };

  return (
    <div className="sidebar-body" style={{ display: loadedPanelActive ? 'inherit' : 'none' }}>
      <span style={{ fontStyle: 'italic', padding: '0 8px' }}>Choose file to load it in the player</span>
      <React.Fragment>
        <div className="input-search">
          <span className="prefix-icon">
            <FontAwesomeIcon icon={faSearch} width={16} height={16} />
          </span>
          <div style={{ width: '100%' }}>
            <input placeholder="Search list" {...search} />
          </div>
        </div>
      </React.Fragment>

      <React.Fragment>
        <div className="input-create-folder">
          <span onClick={createMediaProject} className="prefix-icon">
            <FontAwesomeIcon icon={faPlus} width={16} height={16} />
          </span>
          <div style={{ width: '100%' }}>
            <input
              placeholder="Create new folder"
              onKeyDown={(e) => e.key === 'Enter' && createMediaProject()}
              value={inputCreateFolder.value}
              onChange={inputCreateFolder.onChange}
            />
          </div>
        </div>
      </React.Fragment>
      <UploadVideo />
      <div className="sidebar-media">
        {!loaded && (
          <div>
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" width={16} height={16} /> Loading
          </div>
        )}
        <DndProvider backend={HTML5Backend}>
          <DroppableOutsideFolder handleAmendMedia={handleAmendMedia}>
            {renderFolders()}
            {renderMedias(loaded)}
          </DroppableOutsideFolder>
        </DndProvider>
      </div>
    </div>
  );
}

function DraggableMedia({
  media,
  // index,
  // languages,
  // lastTranscribe,
  // languageRegion,
  userHasWritePermission,
  currentClickedMedia,
  handleChangeSelected,
  open,
}: any) {
  const { onStartTranscribe, cancelUploadingMedia } = useSidebar();

  const [collectedProps, drag] = useDrag({
    type: ItemTypes.MEDIA,
    item: { media },
    canDrag() {
      return media.id;
    },
  });

  // const [isOpenModalPayment, setOpenModalPayment] = useState(false);
  // const [isOpenAddCredit, setOpenAddCredit] = useState(false);
  // const [currentAmount, setCurrentAmount] = useState<ExtraEnum>();
  // const [languagesData, setLanguagesData] = useState<string[]>([]);
  // const [currentLanguageRegion, setLanguageRegion] = useState<LanguageRegion>();

  const isCancel = media.isCancel;
  const isClickedMedia = currentClickedMedia && media.id === currentClickedMedia.id;

  const isDownloading = media.status === MediaStatus.Downloading;
  const isDownloaded = media.status === MediaStatus.Downloaded;
  const isUploading = media.status === MediaStatus.Uploading;
  const isTranscribePaid = media.status === MediaStatus.TranscribePaid;
  const isTranslatePaid = media.status === MediaStatus.TranslatePaid;
  const isTranscribing = media.status === MediaStatus.Transcribing;
  const isTranscribed = media.status === MediaStatus.Transcribed;
  const isReady = media.status === MediaStatus.Ready;
  const isTranslated = media.status === MediaStatus.Translated;
  const isTranslating = media.status === MediaStatus.Translating;
  const isFailed = media.status === MediaStatus.Failed;
  const isRejected = media.status === MediaStatus.Rejected;
  const isTranscoding = media.status === MediaStatus.Transcoding;

  const shouldRetry = isRejected || isFailed;
  const shouldTranscribe = isTranscribed || isTranslatePaid || isTranslating;
  // const isReadyToTranslate = isTranscribed || isTranslatePaid;
  // const isReadyToTranscribe = isReady || isTranscribePaid;
  // const shouldShowButtonTranslate = false; // temporarily comment !shouldShowProgressBar && (isTranscribed || isTranslatePaid);

  const shouldShowProgressBar =
    isDownloading || isDownloaded || isUploading || isTranscribing || isTranslating || isTranscoding;
  // const shouldShowProgressBar = isUploading || isTranscribing || isTranslating || isTranscoding;
  const shouldShowButtonTranscribe = !shouldShowProgressBar && (isReady || isTranscribePaid);

  const displayMediaText = media?.description || media?.name || 'noName';

  return (
    <div ref={drag} className={`sidebar-media__item ${isClickedMedia ? 'selected' : ''}`} title={media.name}>
      <div
        style={{ flex: 1, display: 'flex' }}
        className={`${isFailed ? 'failed' : ''}`}
        onClick={() => {
          if (!isFailed) handleChangeSelected(media);
        }}
        title={media.name}
      >
        <div className="con-icon-file">
          {shouldTranscribe ? <IconTranscribe /> : shouldRetry ? <IconRetry /> : <IconFile />}
        </div>
        <div className="media-name">{displayMediaText}</div>

        {/* {shouldShowProgressBar && (
          <div className="con-progress-upload">
            <div style={{ fontSize: 10, textTransform: 'capitalize' }}>{`${media.percentage}% ${
              media.progressText || media.status
            }`}</div>
            <Progress className="progress-upload" value={media.percentage} />
          </div>
        )} */}

        {shouldShowProgressBar && (
          <div>
            <span style={{ fontSize: 12, textTransform: 'capitalize' }}>{media.progressText || media.status} </span>
            <Spinner size="sm" />
          </div>
        )}

        <React.Fragment>
          {shouldShowButtonTranscribe && (
            <React.Fragment>
              <Button
                id={`uncontrolled-popover-${media.id}`}
                className="btn-transcribe"
                onClick={(e) => {
                  e.stopPropagation();
                  onStartTranscribe(media);
                }}
              >
                Transcribe
              </Button>
            </React.Fragment>
          )}
        </React.Fragment>
      </div>

      <div style={{ alignSelf: 'center' }}>
        {userHasWritePermission && (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              if (shouldShowProgressBar) cancelUploadingMedia(media);
              else if (!isCancel) open(media);
            }}
            className="btn-trash"
          >
            <React.Fragment>
              {isCancel ? (
                <Spinner size="sm" />
              ) : (
                <FontAwesomeIcon icon={shouldShowProgressBar ? faTimes : faTrashAlt} width={16} height={16} />
              )}
            </React.Fragment>
          </Button>
        )}
      </div>
    </div>
  );
}

function DroppableFolder({
  folder,
  userHasWritePermission,
  handleAmendMediaFolder,
  renderMedias,
  handleAmendMedia,
  openModalConfirmDeleteFolder,
}: any) {
  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.MEDIA],
    drop({ media }: any, monitor) {
      const didDrop = monitor.didDrop();
      if (didDrop) return;
      if (media.project_folder_id === folder.id) return;

      handleAmendMedia(media.id, folder.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const inputFolderName = useTextInput(folder.name);

  const toggle = () => setIsOpen(!isOpen);
  const toggleEdit = (e: any) => {
    e.stopPropagation();
    setIsEditing(!isEditing);
  };

  function onSaveFolderName() {
    if (
      handleAmendMediaFolder &&
      typeof handleAmendMediaFolder === 'function' &&
      inputFolderName.value !== folder.name
    ) {
      handleAmendMediaFolder(folder.id, inputFolderName.value);
    }
    setIsEditing(!isEditing);
  }

  return (
    <div
      ref={drop}
      className={`sidebar-media__folder ${isOver ? 'over' : ''} ${isOpen ? 'active' : ''}`}
      title={folder.name}
    >
      <Button onClick={toggle} color="link" title={folder.name}>
        <span style={{ paddingRight: 4 }}>
          <FontAwesomeIcon icon={faFolder} width={18} height={18} />
        </span>
        <span className="folder-name">
          {(!isEditing && folder.name) || (
            <input autoFocus onClick={(e) => e.stopPropagation()} onBlur={onSaveFolderName} {...inputFolderName} />
          )}
        </span>
        <span>
          {!isEditing &&
            ((!isOpen && <FontAwesomeIcon icon={faCaretRight} width={18} height={18} />) || (
              <FontAwesomeIcon icon={faCaretDown} width={18} height={18} />
            ))}
        </span>
        <span className="sidebar-media__folder-tool">
          {userHasWritePermission && (
            <React.Fragment>
              <span onClick={toggleEdit}>
                <FontAwesomeIcon icon={faEdit} width={16} height={16} />
              </span>
              <span
                style={{ marginLeft: 4 }}
                onClick={(e) => {
                  e.stopPropagation();
                  openModalConfirmDeleteFolder(folder);
                }}
              >
                <FontAwesomeIcon icon={faTrashAlt} width={16} height={16} />
              </span>
            </React.Fragment>
          )}
        </span>
      </Button>

      <Collapse isOpen={isOpen}>{renderMedias(folder.imported_media)}</Collapse>
    </div>
  );
}

function DroppableOutsideFolder({ handleAmendMedia, children }: any) {
  const [{ isOver }, drop] = useDrop({
    accept: [ItemTypes.MEDIA],
    drop({ media }: any, monitor) {
      const didDrop = monitor.didDrop();
      if (didDrop) return;
      if (media.project_folder_id === null) return;

      handleAmendMedia(media.id, null);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  });

  return (
    <div ref={drop} className={`sidebar-media__outside-folder ${isOver ? 'over' : ''}`}>
      {children}
    </div>
  );
}
